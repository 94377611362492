import {ProjectFieldsService} from '../../fields/project-fields.service';
import {ProjectPopupService} from '../../popups/project-popup.service';
import {ProjectRendererService} from '../../renderers/project-renderer.service';
import {Injectable} from '@angular/core';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Graphic from '@arcgis/core/Graphic';
import Polygon from '@arcgis/core/geometry/Polygon';
import {ProjectLayerData} from '../../../../../../pages/geospatial-viewer/model/project';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {GeoJSONGeometry, GeoJSONGeometryType} from '../../../../../../pages/geospatial-viewer/model/api';
import Polyline from '@arcgis/core/geometry/Polyline';
import {VisualizationColorCode} from '../../../../../../pages/geospatial-viewer/model/visualization';

@Injectable()
export class ProjectLayerService extends Unsubscribable {
    constructor(
        private fieldsService: ProjectFieldsService,
        private popupService: ProjectPopupService,
        private rendererService: ProjectRendererService,
    ) {
        super();
    }

    public prepareProjectLayer(
        data: ProjectLayerData[],
        currentUserId: string,
        layerId: string,
        colorCodeType: VisualizationColorCode,
    ): FeatureLayer {
        const projectLayer: FeatureLayer = new FeatureLayer({
            id: layerId,
            outFields: ['*'],
            objectIdField: 'ObjectID',
            source: this.createGraphics(data, currentUserId, colorCodeType),
            renderer: this.rendererService.customLayerRenderer(colorCodeType),
            fields: this.fieldsService.getCustomMapFields(),
            popupTemplate: {
                title: '{id}',
                outFields: ['*'],
                content: this.popupService.getCustomPopoutFunction,
            },
        });
        return projectLayer;
    }

    /*
     * Create array of Graphics, type = polygon. Return Graphic[]
     */
    private createGraphics(
        data: ProjectLayerData[],
        currentUserId: string,
        colorCodeType: VisualizationColorCode,
    ): Graphic[] {
        return data.map((project: ProjectLayerData) => {
            const projectGeography: GeoJSONGeometry = JSON.parse(project.shapeGeoJson);

            let geometry;
            if (projectGeography.type == GeoJSONGeometryType.Polygon) {
                geometry = new Polygon({
                    rings: projectGeography?.coordinates ? projectGeography.coordinates : [],
                });
            } else if (
                [GeoJSONGeometryType.LineString, GeoJSONGeometryType.MultiLineString].includes(projectGeography.type)
            ) {
                geometry = new Polyline({
                    paths: projectGeography.coordinates,
                });
            }
            return new Graphic({
                geometry: geometry,
                attributes: {
                    // Unique identifiers
                    ObjectID: project.id,
                    id: project.id,
                    // Data
                    projectName: project.name,
                    type: project.projectCreateType,
                    status: project.status,
                    owner: project.projectOwnedByCurrentUser ? 'me' : 'other',
                },
            });
        });
    }
}
