import {
    CustomerDemographicData,
    DataDetails,
    DistrictData,
    GeoJSONGeometry,
    GeoJSONGeometryType,
    RoadMoratoriumData,
    SpatialCustomLayerResponse,
    SpatialEntityData,
} from '../../../../../pages/geospatial-viewer/model/api';
import {Metric} from '../../../../../pages/geospatial-viewer/model/metric';
import {PointLayerService} from './engin-data/point-layer.service';
import {PolylineLayerService} from './engin-data/polyline-layer.service';
import {HeatmapLayerService} from './engin-data/heatmap-layer.service';
import {AdditionalMapLayer, LayerType} from '../../../../../pages/geospatial-viewer/model/layers';
import {RoadMoratoriumLayerService} from './custom-layer/road-moratorium-layer.service';
import {CustomerDemographicLayerService} from './custom-layer/customer-demographic-layer.service';
import {DistrictOverlayLayerService} from './custom-layer/district-overlay-layer.service';
import {DistrictSummarizeLayerService} from './custom-layer/district-summarize-layer.service';
import {ProjectLayerService} from './custom-layer/project-layer.service';
import {Injectable} from '@angular/core';
import {PolygonLayerService} from './engin-data/polygon-layer.service';
import {GeospatialService} from '../helper/geospatial-service';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import {ProjectLayerData} from '../../../../../pages/geospatial-viewer/model/project';
import {Project} from '@core/interfaces/engin/program-management/project';
import {VisualizationColorCode} from '../../../../../pages/geospatial-viewer/model/visualization';

export class AssetLayers {
    layers: FeatureLayer[];
}
export class BubbleLayer {
    layer: FeatureLayer;
}
export class HeatmapLayers {
    layers: FeatureLayer[];
}
export class BlendedLayer {
    layer: FeatureLayer;
}

/**
 * Top-level layer creation for ENGIN data layers (i.e. asset data with different view modes).
 * These functions orchestrate internal calls based on view type etc.
 */
@Injectable()
export class GenerateLayerService {
    constructor(
        private geospatialService: GeospatialService,
        private pointLayerService: PointLayerService,
        private polylineLayerService: PolylineLayerService,
        private heatmapLayerService: HeatmapLayerService,
        private polygonLayerService: PolygonLayerService,
        private roadMoratoriumLayerService: RoadMoratoriumLayerService,
        private customerDemographicLayerService: CustomerDemographicLayerService,
        private districtOverlayLayerService: DistrictOverlayLayerService,
        private districtSummarizeLayerService: DistrictSummarizeLayerService,
        private projectLayerService: ProjectLayerService,
    ) {}

    /**
     * Create point and polyline layers for asset population.
     * @return AssetLayers
     */
    public createAssetFeatureLayers(
        data: SpatialEntityData[],
        dataDetails: DataDetails,
        metric: Metric,
        theme: string,
        settings?: any,
    ): AssetLayers {
        const layers: FeatureLayer[] = [];
        const preparedData = this.geospatialService.prepareEnginData(data, metric, dataDetails);
        // Prepare point and linear data lists
        const pointData: SpatialEntityData[] = preparedData.filter(
            (a) => a.geoJsonGeometry.type === GeoJSONGeometryType.Point,
        );
        const linearData: SpatialEntityData[] = preparedData.filter(
            (a) =>
                a.geoJsonGeometry.type === GeoJSONGeometryType.LineString ||
                a.geoJsonGeometry.type === GeoJSONGeometryType.MultiLineString,
        );

        // Create  point layers (point layer and overlay for overlapping points) and polyline layer
        if (pointData.length > 0) {
            const pointLayers = this.pointLayerService.preparePointLayers(
                pointData,
                'asset_point_layer',
                theme,
                settings,
            );
            layers.push(...pointLayers);
        }
        if (linearData.length > 0) {
            const linearLayer = this.polylineLayerService.preparePolylineLayer(
                true,
                linearData,
                'asset_line_layer',
                theme,
            );
            layers.push(linearLayer);
        }

        return {
            layers: [...layers],
        };
    }

    /**
     * Create polyline layers for group lines.
     * @return AssetLayers
     */
    public createGroupFeatureLayers(
        data: SpatialEntityData[],
        dataDetails: DataDetails,
        metric: Metric,
        theme: string,
    ): AssetLayers {
        const layers: FeatureLayer[] = [];
        const preparedData = this.geospatialService.prepareEnginData(data, metric, dataDetails);
        // Prepare point and linear data lists
        const linearData: SpatialEntityData[] = preparedData.filter(
            (a) =>
                a.geoJsonGeometry.type === GeoJSONGeometryType.LineString ||
                a.geoJsonGeometry.type === GeoJSONGeometryType.MultiLineString,
        );

        //create a polylineLayer
        if (linearData.length > 0) {
            const linearLayer = this.polylineLayerService.preparePolylineLayer(
                false,
                linearData,
                'group_line_layer',
                theme,
            );
            layers.push(linearLayer);
        }

        return {
            layers: [...layers],
        };
    }

    /**
     * Create bubble layer for asset population.
     * @return BubbleLayer
     */
    public createAssetBubbleLayer(
        data: SpatialEntityData[],
        dataDetails: DataDetails,
        metric: Metric,
        theme: string,
    ): BubbleLayer {
        const preparedData = this.geospatialService.prepareEnginData(data, metric, dataDetails);
        // Expect data will already be points with count >= 1 for larger bubbles
        const centerPointData: SpatialEntityData[] = preparedData.filter(
            (a) => a.geoJsonGeometry.type === GeoJSONGeometryType.Point,
        );

        // Create (zoomed out) bubble layer
        const combinedLayer = this.pointLayerService.preparePointBubbleLayer(centerPointData, theme);

        return {
            layer: combinedLayer,
        };
    }

    /**
     * Return heatmap layers (one per category) for asset population.
     * Linear assets are consolidated to a point with average [X,Y].
     * @return HeatmapLayers
     */
    public createHeatmapFeatureLayers(
        data: SpatialEntityData[],
        dataDetails: DataDetails,
        metric: Metric,
        theme: string,
        zoom: number,
    ): HeatmapLayers {
        const preparedData = this.geospatialService.prepareEnginData(data, metric, dataDetails);
        // Prepare point, linear, and combined (zoomed out) data lists
        const pointData: SpatialEntityData[] = preparedData.filter(
            (a) => a.geoJsonGeometry.type === GeoJSONGeometryType.Point,
        );
        const linearData: SpatialEntityData[] = preparedData.filter(
            (a) => a.geoJsonGeometry.type === GeoJSONGeometryType.LineString,
        );
        const combinedData: SpatialEntityData[] = this.combinePointLinearData(pointData, linearData);

        const heatmapLayers = this.heatmapLayerService.prepareHeatmapLayers(
            combinedData,
            'asset_heatmap_layer',
            theme,
            zoom,
        );

        return {
            layers: heatmapLayers,
        };
    }

    /**
     * Create polyline layer for asset population based on blended (region) data.
     * @return BlendedLayer
     */
    public createAssetBlendedLayers(
        data: SpatialEntityData[],
        dataDetails: DataDetails,
        metric: Metric,
        theme: string,
    ): BlendedLayer {
        const preparedData = this.geospatialService
            .prepareEnginData(data, metric, dataDetails)
            .filter((a) => a.geoJsonGeometry.type == GeoJSONGeometryType.MultiLineString);

        const regionLayer = this.polylineLayerService.preparePolylineLayer(
            false,
            preparedData,
            'asset_blended_layer',
            theme,
        );

        return {
            layer: regionLayer,
        };
    }

    /**
     * Create polygon layer for asset population based on aggregated asset data.
     * @return esri.FeatureLayer
     */
    public createAssetPolygonLayer(
        data: SpatialEntityData[],
        dataDetails: DataDetails,
        metric: Metric,
        theme: string,
    ): FeatureLayer {
        const preparedData = this.geospatialService
            .prepareEnginData(data, metric, dataDetails)
            .filter((a) => a.geoJsonGeometry.type == GeoJSONGeometryType.Polygon);

        const polygonLayer = this.polygonLayerService.preparePolygonLayer(preparedData, 'asset_polygon_layer', theme);

        return polygonLayer;
    }

    /**
     * Generate project layer for map
     * @private
     */
    public createProjectLayer(
        projects: Project[],
        currentUserId: string,
        colorCodeType?: VisualizationColorCode,
    ): FeatureLayer {
        const preparedData: ProjectLayerData[] = this.geospatialService.prepareProjectData(projects, currentUserId);
        return this.projectLayerService.prepareProjectLayer(preparedData, currentUserId, 'project', colorCodeType);
    }

    /**
     * Generate custom data layer for map
     * @private
     */
    public createAdditionalMapLayer(layer: AdditionalMapLayer, layerData: SpatialCustomLayerResponse): FeatureLayer {
        const preparedData = this.geospatialService.prepareAdditionalMapData(layerData.data);

        // Prepare layer from data source
        switch (layer.code) {
            case LayerType.ROAD_MORATORIUM:
                const roadData: RoadMoratoriumData[] = preparedData as RoadMoratoriumData[];
                return this.roadMoratoriumLayerService.prepareRoadMoratoriumsLayer(layer, roadData, layerData.details);
            case LayerType.DEMOGRAPHIC_CUSTOMER:
                const demoData: CustomerDemographicData[] = preparedData as CustomerDemographicData[];
                return this.customerDemographicLayerService.prepareCustomerDemographicLayer(
                    layer,
                    demoData,
                    layerData.details,
                );
            case LayerType.DISTRICT_OVERLAY:
                const overlayData: DistrictData[] = preparedData as DistrictData[];
                return this.districtOverlayLayerService.prepareDistrictOverlayLayer(
                    layer,
                    overlayData,
                    layerData.details,
                );
            case LayerType.DISTRICT_SUMMARIZE:
                const summarizeData: DistrictData[] = preparedData as DistrictData[];
                return this.districtSummarizeLayerService.prepareDistrictSummarizeLayer(
                    layer,
                    summarizeData,
                    layerData.details,
                );
            case LayerType.DISTRICT:
            case LayerType.DEMOGRAPHIC:
            default:
                return null;
        }
    }

    private combinePointLinearData(
        pointData: SpatialEntityData[],
        linearData: SpatialEntityData[],
    ): SpatialEntityData[] {
        const avgFn = (arr: number[]) => {
            const sum = arr.reduce((a, b) => a + b, 0);
            const len = arr.length;
            return len >= 0 ? sum / len : 0;
        };

        return [].concat(
            pointData,
            linearData.map((e) => {
                const newGeometry: GeoJSONGeometry = new GeoJSONGeometry(GeoJSONGeometryType.Point, [
                    avgFn(e.geoJsonGeometry.coordinates.map((c) => c[0])),
                    avgFn(e.geoJsonGeometry.coordinates.map((c) => c[1])),
                ]);
                return {
                    ...e,
                    geoJsonGeometry: newGeometry,
                    geoJsonGeometryStr: null,
                };
            }),
        );
    }
}
