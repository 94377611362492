import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '@core/backend/common/api/http.service';
import {
    GeoJSONGeometry,
    GeospatialAreaPmCountRequest,
    GeospatialAreaPmCountResponse,
    GeospatialAssetPopupRequest,
    GeospatialAssetPopupResponse,
    GeospatialGroupPopupRequest,
    GeospatialGroupPopupResponse,
    GeospatialRequest,
    GeospatialRequestAlt,
    GeospatialResponse,
    SpatialCustomLayerResponse,
} from '../model/api';
import {map} from 'rxjs/operators';
import {LayerType, MapLayerDetails} from '../model/layers';
import {Project, ProjectDto} from '@core/interfaces/engin/program-management/project';
import {
    GeospatialResponseAlt,
    SpatialCardResponseAlt,
} from '@theme/components/spatial-card/model/model-spatial-card-alt';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class GeospatialViewerApi {
    private prefix = 'geospatial';
    private prefixProject = 'projects';

    constructor(private api: HttpService) {}

    // Core map data
    public getMapGeoData(studyId: number, req: GeospatialRequest): Observable<GeospatialResponse> {
        return this.api.post(`${this.prefix}/${studyId}`, req);
    }

    public getAssetPopupInfo(
        studyId: number,
        req: GeospatialAssetPopupRequest,
    ): Observable<GeospatialAssetPopupResponse> {
        return this.api.post(`${this.prefix}/details/asset/${studyId}`, req);
    }

    public getAssetGroupPopupInfo(
        studyId: number,
        req: GeospatialGroupPopupRequest,
    ): Observable<GeospatialGroupPopupResponse> {
        return this.api.post(`${this.prefix}/details/group/${studyId}`, req);
    }

    // Additional layers
    public getCustomMapLayer(code: string): Observable<SpatialCustomLayerResponse> {
        return this.api.get(`${this.prefix}/layers/custom/${code}`);
    }

    public getCustomMapLayerDetails(type: LayerType): Observable<MapLayerDetails[]> {
        return this.api.get(`${this.prefix}/layers/custom/${type}/details`);
    }

    // Project APIs
    public listProjectsByField(field: {key: string; value: string}): Observable<Project[]> {
        const query = `?filterBy${field.key}=${field.value}&pageNumber=1&pageSize=1`;
        return this.api.get(`${this.prefixProject}/list${query}`).pipe(
            map((res) => {
                return res.items;
            }),
        );
    }
    public getProjectDtoListWithParams(
        workflowItemId: number,
        filterQuery: string,
        pageSize?: number,
    ): Observable<ProjectDto[]> {
        const sortQuery = '&sortBy=name&orderBy=asc';
        const query = `?pageNumber=1&pageSize=${pageSize}${sortQuery}` + (filterQuery ? `&${filterQuery}` : '');
        return this.api.get(`${this.prefixProject}/dto/${workflowItemId}/list${query}`).pipe(
            map((res) => {
                return res.items;
            }),
        );
    }

    // Project support
    public queryAssetsInPolygon(studyId: number, geoJsonStr: string): Observable<{count: number}> {
        const req = {
            geoJsonGeometry: geoJsonStr,
        };
        return this.api.post(`${this.prefix}/${studyId}/count`, req);
    }
    public getSearchAssetList(req: string): Observable<any> {
        const query = `?assetId=${req}`;
        return this.api.get(`${this.prefix}/search/asset${query}`);
    }
    public getSearchAssetInfo(req: string): Observable<GeoJSONGeometry> {
        const query = `?assetId=${req}`;
        return this.api.get(`${this.prefix}/asset${query}`);
    }

    public getGeospatialMapDataByProgram(
        studyId: number,
        programId: number,
        req: GeospatialRequest,
    ): Observable<GeospatialResponse> {
        return this.api.post(`${this.prefix}/data/${studyId}/program/${programId}`, req);
    }

    public getGeospatialMapDataByProject(
        studyId: number,
        projectId: number,
        req: GeospatialRequest,
    ): Observable<GeospatialResponse> {
        return this.api.post(`${this.prefix}/data/${studyId}/project/${projectId}`, req);
    }

    public getGeospatialMapDataBySegment(
        studyId: number,
        segmentId: number,
        req: GeospatialRequest,
    ): Observable<GeospatialResponse> {
        return this.api.post(`${this.prefix}/data/${studyId}/segment/${segmentId}`, req);
    }

    public getCountOfAssetByAreaPm(request: GeospatialAreaPmCountRequest): Observable<GeospatialAreaPmCountResponse> {
        return this.api.post(`${this.prefix}/pm/count`, request).pipe(map((data) => data.count));
    }

    // Alternative; TODO: move to project and alternative services.
    public getGeospatialCardDataByProject(
        projectId: number,
        req: GeospatialRequestAlt,
    ): Observable<APIResponse<SpatialCardResponseAlt>> {
        return this.api.post(`${this.prefix}/project/${projectId}`, req);
    }

    public getGeospatialCardDataByAlternative(
        alternativeId: number,
        req: GeospatialRequestAlt,
    ): Observable<APIResponse<SpatialCardResponseAlt>> {
        return this.api.post(`${this.prefix}/alternative/${alternativeId}`, req);
    }
}
