<nb-card class="spatial-container">
    <nb-card-header *ngIf="headerData | async as headerInfo">
        <div class="spatial-header">
            <div class="title">
                {{ title }}
            </div>
            <div class="info-container">
                <div class="info-row" *ngFor="let info of headerInfo">
                    <div class="info-box">
                        <p class="label">{{ info.label }}</p>
                        <p class="value">{{ info.unit ? info.unit : "" }} {{ info.numerator }}{{ info.denominator }}</p>
                    </div>
                </div>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <div #mapViewNode class="map-container" id="viewDiv">
            <ng-container *ngIf="type !== spatialCardType.APP_REGISTRY">
                <div class="map-controls-container">
                    <div>
                        <ngx-measure-metric
                            *ngIf="configData$ | async"
                            [options]="configData$"
                            (onSelectChange)="onUpdateMetric($event)"
                        ></ngx-measure-metric>
                    </div>
                </div>
                <div *ngIf="legendColor$ | async as legendColor">
                    <ngx-legend-bar [legendData]="legendColor" [valueType]="valueType"></ngx-legend-bar>
                </div>
            </ng-container>
        </div>
    </nb-card-body>
</nb-card>
<ng-container *ngIf="type === spatialCardType.APP_REGISTRY">
    <nb-accordion>
        <nb-accordion-item>
            <nb-accordion-item-header>Tooltips</nb-accordion-item-header>
            <nb-accordion-item-body>
                Asset ID
                <nb-toggle [formControl]="toggleFormControl"></nb-toggle>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
</ng-container>
