import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GeospatialViewerApi} from './geospatial-viewer.api';
import {
    GeoJSONGeometry,
    GeospatialAreaPmCountRequest,
    GeospatialAreaPmCountResponse,
    GeospatialAssetPopupRequest,
    GeospatialAssetPopupResponse,
    GeospatialGroupPopupRequest,
    GeospatialGroupPopupResponse,
    GeospatialRequest,
    GeospatialRequestAlt,
    GeospatialResponse,
    SpatialCustomLayerResponse,
} from '../model/api';
import {LayerType, MapLayerDetails} from '../model/layers';
import {Project, ProjectDto} from '@core/interfaces/engin/program-management/project';
import {
    GeospatialResponseAlt,
    SpatialCardResponseAlt,
} from '@theme/components/spatial-card/model/model-spatial-card-alt';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class GeospatialViewerService {
    constructor(private api: GeospatialViewerApi) {}

    // Core map data
    public getMapGeoData(studyId: number, request: GeospatialRequest): Observable<GeospatialResponse> {
        return this.api.getMapGeoData(studyId, request);
    }

    public getAssetPopupInfo(
        studyId: number,
        req: GeospatialAssetPopupRequest,
    ): Observable<GeospatialAssetPopupResponse> {
        return this.api.getAssetPopupInfo(studyId, req);
    }

    public getAssetGroupPopupInfo(
        studyId: number,
        req: GeospatialGroupPopupRequest,
    ): Observable<GeospatialGroupPopupResponse> {
        return this.api.getAssetGroupPopupInfo(studyId, req);
    }

    // Additional layers
    getCustomMapLayer(code: string): Observable<SpatialCustomLayerResponse> {
        return this.api.getCustomMapLayer(code);
    }

    public getCustomMapLayerDetails(type: LayerType): Observable<MapLayerDetails[]> {
        return this.api.getCustomMapLayerDetails(type);
    }

    // Project APIs
    // TODO: these need to refactored to use existing APIs, or be moved into  project.service.ts.
    public listProjectsByField(field: {key: string; value: string}): Observable<Project[]> {
        return this.api.listProjectsByField(field);
    }
    public getProjectDtoListWithParams(
        workflowItemId: number,
        filterQuery: string,
        pageSize?: number,
    ): Observable<ProjectDto[]> {
        return this.api.getProjectDtoListWithParams(workflowItemId, filterQuery, pageSize);
    }

    // Project support
    // TODO: can/should these be refactored into project.service.ts? These currently going to geospatial API.
    public queryAssetsInPolygon(studyId: number, geoJsonStr: string): Observable<{count: number}> {
        return this.api.queryAssetsInPolygon(studyId, geoJsonStr);
    }
    public getSearchAssetList(req: string): Observable<any> {
        return this.api.getSearchAssetList(req);
    }
    public getSearchAssetInfo(req: string): Observable<GeoJSONGeometry> {
        return this.api.getSearchAssetInfo(req);
    }

    // Spacial Card
    public getGeospatialMapDataByProgram(
        studyId: number,
        programId: number,
        request: GeospatialRequest,
    ): Observable<GeospatialResponse> {
        return this.api.getGeospatialMapDataByProgram(studyId, programId, request);
    }
    public getGeospatialMapDataByProject(
        studyId: number,
        projectId: number,
        request: GeospatialRequest,
    ): Observable<GeospatialResponse> {
        return this.api.getGeospatialMapDataByProject(studyId, projectId, request);
    }
    public getGeospatialMapDataBySegment(
        studyId: number,
        segmentId: number,
        request: GeospatialRequest,
    ): Observable<GeospatialResponse> {
        return this.api.getGeospatialMapDataBySegment(studyId, segmentId, request);
    }

    public getCountOfAssetByAreaPm(request: GeospatialAreaPmCountRequest): Observable<GeospatialAreaPmCountResponse> {
        return this.api.getCountOfAssetByAreaPm(request);
    }

    // Alternative; TODO: move to project and alternative services.
    public getGeospatialCardDataByProject(
        projectId: number,
        request: GeospatialRequestAlt,
    ): Observable<APIResponse<SpatialCardResponseAlt>> {
        return this.api.getGeospatialCardDataByProject(projectId, request);
    }

    public getGeospatialCardDataByAlternative(
        alternativeId: number,
        request: GeospatialRequestAlt,
    ): Observable<APIResponse<SpatialCardResponseAlt>> {
        return this.api.getGeospatialCardDataByAlternative(alternativeId, request);
    }
}
