import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {
    Measure,
    MeasureType,
    MeasureTypeLabel,
    Metric,
    MetricType,
    MetricTypeLabel,
} from '../../../../../pages/geospatial-viewer/model/metric';
import {BehaviorSubject, Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {GeospatialViewerConfiguration} from '../../../../../pages/geospatial-viewer/model/viewer-config';
import {MeasureMetricService} from '@theme/components/spatial-card/service/measure-metric/measure-metric.service';

@Component({
    selector: 'ngx-measure-metric',
    templateUrl: './measure-metric.component.html',
    styleUrls: ['./measure-metric.component.scss'],
    providers: [MeasureMetricService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeasureMetricComponent extends Unsubscribable implements OnInit {
    @Input() options: Observable<Array<Measure>> = new BehaviorSubject<Array<Measure>>([]);
    @Output() onSelectChange: EventEmitter<{measure: Measure; metric: Metric}> = new EventEmitter();
    @Input() optionsFixed: boolean = false;
    @Input() optionsStatic: Array<Measure> = [];

    measureItems: Measure[] = [];
    metricItems: Metric[] = [];
    private activeMeasure: BehaviorSubject<Measure> = new BehaviorSubject<Measure>(null);
    private activeMetric: BehaviorSubject<Metric> = new BehaviorSubject<Metric>(null);
    readonly activeMeasure$: Observable<Measure> = this.activeMeasure.asObservable();
    readonly activeMetric$: Observable<Metric> = this.activeMetric.asObservable();

    constructor(private measureMetricService: MeasureMetricService) {
        super();
    }

    ngOnInit() {
        if (this.optionsFixed) {
            const activeMeasure: Measure = this.measureMetricService.getDefaultMeasure(this.optionsStatic);
            const activeMetric: Metric = this.measureMetricService.getDefaultMetric(activeMeasure);

            this.measureItems = this.optionsStatic;

            if (this.activeMeasure.value === null) {
                this.metricItems = activeMeasure.metrics;
                this.activeMeasure.next(activeMeasure);
                this.activeMetric.next(activeMetric);
            }
        } else {
            this.options.pipe(takeUntil(this.unsubscribe$)).subscribe((options: Array<Measure>) => {
                const activeMeasure: Measure = this.measureMetricService.getDefaultMeasure(options);
                const activeMetric: Metric = this.measureMetricService.getDefaultMetric(activeMeasure);

                this.measureItems = options;

                if (this.activeMeasure.value === null) {
                    this.metricItems = activeMeasure.metrics;
                    this.activeMeasure.next(activeMeasure);
                    this.activeMetric.next(activeMetric);
                }
            });
        }
    }

    // Update selected measure, if the new value is different from the current value. Clear selected metric.
    public onMeasureSelect(measureCode) {
        if (measureCode && this.activeMeasure.value.code !== measureCode) {
            const fullMeasure: Measure = this.measureItems.filter((m) => m.code === measureCode)[0];
            this.updateMeasure(fullMeasure);
            this.activeMetric.next(null);
            this.metricItems = this.activeMeasure.value.metrics;
        }
    }

    // Update selected metric, if the new value is different from the current value
    public onMetricSelect(metricCode) {
        if (metricCode && (this.activeMetric.value == null || this.activeMetric.value.code !== metricCode)) {
            const activeMeasure: Measure = this.measureItems.filter((m) => m.code === this.activeMeasure.value.code)[0];
            const fullMetric: Metric = activeMeasure.metrics.filter((m) => m.code === metricCode)[0];
            this.updateMetric(fullMetric);
            this.onSelectChange.emit({measure: this.activeMeasure.value, metric: this.activeMetric.value});
        }
    }

    public getMeasureLabel(type: MeasureType): string {
        return MeasureTypeLabel[type];
    }

    public getMetricLabel(type: MetricType): string {
        return MetricTypeLabel[type];
    }

    // Update selected measure, and reset selected metric to nothing
    public updateMeasure(newMeasure: Measure) {
        if (newMeasure && this.activeMeasure.value.code !== newMeasure.code) {
            this.activeMetric.next(null); // force null
            this.activeMeasure.next(newMeasure);
        }
    }

    public updateMetric(newMetric: Metric) {
        if (newMetric && (this.activeMetric.value == null || this.activeMetric.value.code !== newMetric.code)) {
            this.activeMetric.next(newMetric);
        }
    }
}
