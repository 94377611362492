import {StudiesStore} from '@store/common/studies.store';
import {GeospatialViewerService} from '../../../../../pages/geospatial-viewer/api/geospatial-viewer.service';
import {Injectable} from '@angular/core';
import {UsersStore} from '@store/common/users.store';
import {filter} from 'rxjs/operators';
import {User} from '@core/interfaces/common/users';
import {WorkflowType} from '@core/interfaces/engin/workflow';
import {APIResponse, Filter} from '@core/interfaces/system/system-common';
import {FormatsService} from '@core/utils';
import {ProjectDto, ProjectService} from '@core/interfaces/engin/program-management/project';

@Injectable()
export class ProjectPopupService {
    /*
     * Render popup for road closure feature. Works for point or line features equivalently.
     */
    constructor(
        private studiesStore: StudiesStore,
        private geospatialViewerService: GeospatialViewerService,
        private projectService: ProjectService,
        private userStore: UsersStore,
    ) {}

    getCustomPopoutFunction = (feature) => {
        // From feature
        const projectId: string = feature.graphic.attributes.ObjectID;
        const projectIdNumber: number = Number(projectId);

        // From stores
        let currentUserId;
        this.userStore.currentUser$.pipe(filter((u: User) => u && !!u)).subscribe((x) => {
            currentUserId = x.id;
        });
        return this.projectService
            .getProjectDto(this.studiesStore.getActiveStudyIdByType(WorkflowType.STUDY), projectIdNumber)
            .toPromise()
            .then((res: APIResponse<ProjectDto>) => {
                return this.renderProjectPopup(feature, res.response, currentUserId);
            });
    };

    renderProjectPopup(feature: any, project: ProjectDto, currentUserId: string) {
        const div = document.createElement('div');

        // layer = 'c' is street view; 'cbll' is lat/long for streetview; 'll' is lat/long for default map
        const lat = feature?.graphic?.geometry?.centroid?.latitude;
        const long = feature?.graphic?.geometry?.centroid?.longitude;
        const streetViewUrl = `http://maps.google.com/maps?layer=c&cbll=${lat},${long}`;
        const mapsUrl = `http://maps.google.com/maps?ll=${lat},${long}`;
        const mainLabelStyle =
            'font-family: Roboto; font-size: 14px; font-style: normal; font-weight: 700; line-height: 20px;';
        const subLabelStyle =
            'font-size: 14px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 8px; width: 50%;';
        const valueStyle = 'font-size: 14px; font-style: normal; font-weight: 400; line-height: 16px; width: 50%;';
        const divStyle = 'display: flex; justify-content: space-between;';
        const extraHtml =
            lat && long
                ? `
        <div class='row justify-content-start' style="margin-top: 24px">
            <div class='col-3 p-0'>
            <p style="${mainLabelStyle}">External links: </p>
            </div>
            <a class='col-4 p-0' href="${streetViewUrl}" target="_blank" style="display: flex; padding: 0">
              Google Street View
              <calcite-icon style="margin-left: 4px; width: 16px; height: 16px; min-height: 0; min-width: 0;" icon="launch"/>
            </a>

            <a class='col-4 p-0' href="${mapsUrl}" target="_blank" style="display: flex; padding: 0; margin-left: 24px">
              Google Maps
              <calcite-icon style="margin-left: 4px; width: 16px; height: 16px; min-height: 0; min-width: 0;" icon="launch"/>
            </a>
        </div>
        `
                : '';

        const filters: Filter[] = project?.filters as Filter[];
        const appliedFilterCount = filters.length;
        const formattedCostValue = FormatsService.prepareCostValue(project.assetTotalCost, '$', '');
        const popupHeader = document.createElement('div');
        popupHeader.style.position = 'absolute';
        popupHeader.style.top = '6px';
        popupHeader.style.left = '8px';
        popupHeader.style.display = 'flex';
        popupHeader.style.alignItems = 'center';

        // TODO: Update the icon to use a Calcite icon instead of an Eva icon. The designer is currently working on it.
        popupHeader.innerHTML = `
        <calcite-icon icon="apps" ></calcite-icon>
        <p style="font-size: 14px; font-style: normal; font-weight: 700; line-height: 20px; display: inline-block; margin: 0 0 0 8px">
            Project Name: ${project.name}
        </p>
    `;

        div.innerHTML = `
                        <div class="headers" style="display: flex;align-items: center;margin-top: 7px;">
                            <calcite-icon icon="apps" ></calcite-icon>
                            <p style="font-size: 14px; font-style: normal; font-weight: 700; line-height: 20px; display: inline-block; margin: 0 0 0 0">
                                Project Name: ${project.name}
                            </p>
                        </div>
                        <div class='container projectPopup' style="margin-top: 17px">
                            <div class="row">
                                <p style="${mainLabelStyle}">Project Details</p>
                            </div>
                            <div class="row">
                                <div class="col-12 p-0">
                                    <div style="${divStyle}">
                                    <p style="${subLabelStyle}">Number of asset</p>
                                    <p style="${valueStyle}">${project.assetCount}</p>
                                    </div>
                                    <div style="${divStyle}">
                                    <p style="${subLabelStyle}">Project Name</p>
                                    <p style="${valueStyle}">${project.name}</p>
                                    </div>
                                    <div style="${divStyle}">
                                    <p style="${subLabelStyle}">Description</p>
                                    <p style="${valueStyle}">${project.description}</p>
                                    </div>
                                    <div style="${divStyle}">
                                    <p style="${subLabelStyle}">Program</p>
                                    <p style="${valueStyle}">${project.programName}</p>
                                    </div>
                                    <div style="${divStyle}">
                                    <p style="${subLabelStyle}">Investment Driver</p>
                                    <p style="${valueStyle}">${project.investmentDriver}</p>
                                    </div>
                                    <div style="${divStyle}">
                                    <p style="${subLabelStyle}">Applied Filters</p>
                                    <p style="${valueStyle}">${appliedFilterCount}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="row" style="padding-top:16px">
                                <p style="${mainLabelStyle}">Replacement Cost</p>
                            </div>
                            <div class="row">
                                <div class="col-12 p-0">
                                <div style="${divStyle}">
                                    <p style="${subLabelStyle}">Total Cost</p>
                                    <p style="${valueStyle}">${formattedCostValue}</p>
                                    </div>
                                </div>
                            </div>

                            ${extraHtml}
                      </div>`;
        return div;
    }
}
