import Renderer from '@arcgis/core/renderers/Renderer';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import {VisualizationColorCode} from '../../../../../pages/geospatial-viewer/model/visualization';
import {ProjectStatus} from '../../../../../pages/geospatial-viewer-old/model/project';
import {ProjectStatusType} from '@core/interfaces/engin/program-management/project';

export class ProjectRendererService {
    public customLayerRenderer(colorCodeType: VisualizationColorCode = VisualizationColorCode.OWNERSHIP): Renderer {
        switch (colorCodeType) {
            case VisualizationColorCode.OWNERSHIP:
                return new UniqueValueRenderer({
                    field: 'owner',
                    uniqueValueInfos: [].concat([
                        {
                            value: 'me',
                            symbol: {
                                type: 'simple-fill',
                                ...ProjectRendererService.getRenderDetailsMyProject(),
                            },
                        },
                        {
                            value: 'other',
                            symbol: {
                                type: 'simple-fill',
                                ...ProjectRendererService.getRenderDetailsOtherProject(),
                            },
                        },
                    ]),
                });
            case VisualizationColorCode.STATUS:
                return new UniqueValueRenderer({
                    field: 'status',
                    uniqueValueInfos: [].concat([
                        {
                            value: 'SCOPE',
                            symbol: {
                                type: 'simple-fill',
                                ...ProjectRendererService.getRenderDetailsScope(),
                            },
                        },
                        {
                            value: 'PROJECT',
                            symbol: {
                                type: 'simple-fill',
                                ...ProjectRendererService.getRenderDetailsProject(),
                            },
                        },
                        {
                            value: 'BUIlT',
                            symbol: {
                                type: 'simple-fill',
                                ...ProjectRendererService.getRenderDetailsBuilt(),
                            },
                        },
                        {
                            value: 'GENERATED',
                            symbol: {
                                type: 'simple-fill',
                                ...ProjectRendererService.getRenderDetailsGenerated(),
                            },
                        },
                    ]),
                });
        }
    }
    private static getRenderDetailsScope() {
        return {
            color: [250, 211, 109, 0.16],
            outline: {
                color: '#FAD36D29',
                width: 1,
            },
        };
    }
    private static getRenderDetailsProject() {
        return {
            color: [76, 165, 255, 0.16],
            outline: {
                color: '#4CA5FF',
                width: 1,
            },
        };
    }
    private static getRenderDetailsBuilt() {
        return {
            color: [0, 179, 131, 0.16],
            outline: {
                color: '#00B383',
                width: 1,
            },
        };
    }
    private static getRenderDetailsGenerated() {
        return {
            color: [195, 106, 237, 0.16],
            outline: {
                color: '#C36AED',
                width: 1,
            },
        };
    }

    private static getRenderDetailsScopeFocus() {
        return {
            color: [250, 211, 109, 0.4],
            outline: {
                color: '#FAD36D',
                width: 1,
            },
        };
    }
    private static getRenderDetailsProjectFocus() {
        return {
            color: [76, 165, 255, 0.4],
            outline: {
                color: '#4CA5FF',
                width: 1,
            },
        };
    }
    private static getRenderDetailsBuiltFocus() {
        return {
            color: [0, 179, 131, 0.4],
            outline: {
                color: '#00B383',
                width: 1,
            },
        };
    }
    public static getRenderDetailsGeneratedFocus() {
        return {
            color: [195, 106, 237, 0.4],
            outline: {
                color: '#C36AED',
                width: 1,
            },
        };
    }

    private static getRenderDetailsMyProject() {
        return {
            color: [12, 138, 199, 0.1],
            outline: {
                color: '#0C8AC7',
                width: 1,
            },
        };
    }
    private static getRenderDetailsOtherProject() {
        return {
            color: [42, 52, 79, 0.08],
            outline: {
                color: '#abaebd',
                width: 1,
            },
        };
    }
    private static getRenderDetailsMyProjectFocus() {
        return {
            color: [12, 138, 199, 0.16],
            outline: {
                color: '#066fa2',
                width: 2,
            },
        };
    }
    private static getRenderDetailsOtherProjectFocus() {
        return {
            color: [42, 52, 79, 0.16],
            outline: {
                color: '#424d69',
                width: 2,
            },
        };
    }
    private static getRenderDetailsMyProjectEdit() {
        return {
            color: [12, 138, 199, 0.16],
            outline: new SimpleLineSymbol({
                color: '#066fa2',
                width: 2,
                style: 'dash',
            }),
        };
    }
    private static getRenderDetailsOtherProjectEdit() {
        return {
            color: [42, 52, 79, 0.16],
            outline: new SimpleLineSymbol({
                color: '#424d69',
                width: 2,
                style: 'dash',
            }),
        };
    }

    public static getSimpleStatusFillSymbolFocus(status: ProjectStatusType) {
        switch (status) {
            case ProjectStatusType.SCOPE:
                return new SimpleFillSymbol(ProjectRendererService.getRenderDetailsScopeFocus());
            case ProjectStatusType.PROJECT:
                return new SimpleFillSymbol(ProjectRendererService.getRenderDetailsProjectFocus());
            case ProjectStatusType.BUILT:
                return new SimpleFillSymbol(ProjectRendererService.getRenderDetailsBuiltFocus());
            case ProjectStatusType.GENERATED:
                return new SimpleFillSymbol(ProjectRendererService.getRenderDetailsGeneratedFocus());
        }
    }

    public static getSimpleFillSymbolFocus(currentUser: boolean) {
        if (currentUser) {
            return new SimpleFillSymbol(ProjectRendererService.getRenderDetailsMyProjectFocus());
        }
        return new SimpleFillSymbol(ProjectRendererService.getRenderDetailsOtherProjectFocus());
    }
    public static getSimpleFillSymbolEdit(currentUser: boolean) {
        if (currentUser) {
            return new SimpleFillSymbol(ProjectRendererService.getRenderDetailsMyProjectEdit());
        }
        return new SimpleFillSymbol(ProjectRendererService.getRenderDetailsOtherProjectEdit());
    }

    public static getStatusHighLightOptionColor(status: ProjectStatus) {
        const getRGBObject = (colorArray: number[]) => ({
            r: colorArray[0],
            g: colorArray[1],
            b: colorArray[2],
        });

        switch (status) {
            case ProjectStatus.SCOPE:
                return getRGBObject(ProjectRendererService.getRenderDetailsScopeFocus().color);
            case ProjectStatus.PROJECT:
                return getRGBObject(ProjectRendererService.getRenderDetailsProjectFocus().color);
            case ProjectStatus.BUILT:
                return getRGBObject(ProjectRendererService.getRenderDetailsBuiltFocus().color);
            case ProjectStatus.GENERATED:
                return getRGBObject(ProjectRendererService.getRenderDetailsGeneratedFocus().color);
        }
    }
}
