import {Injectable} from '@angular/core';
import {NbAuthService} from '@nebular/auth';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {NbToastrService} from '@nebular/theme';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: NbAuthService, private router: Router, private toastrService: NbToastrService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.authService.isAuthenticated().pipe(
            tap((authenticated) => {
                const jwt = JSON.parse(localStorage.getItem('auth_app_token'));
                if (jwt != null) {
                    // Clear old JWT which do not have an expiration date (created prevChart 2020-01-11, edit 2020-03-01)
                    if (jwt.createdAt < 1578700800000) {
                        // 1583067600000
                        localStorage.removeItem('auth_app_token');
                        this.router.navigate(['auth/login']);
                    }
                }

                // If JWT is invalid, remove active study and redirect to login page
                if (!authenticated) {
                    localStorage.clear();
                    this.router.navigate(['auth/login']);
                    if (jwt != null) {
                        setTimeout(() => {
                            this.toastrService.warning(
                                'Session has expired and you have been automatically logged out. ' +
                                    'Please log back in.',
                                'Session is expired',
                                {duration: 0},
                            );
                        }, 1500);
                    }
                }
            }),
        );
    }
}
