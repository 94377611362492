import {
    MeasureType,
    MeasureTypeLabel,
    MetricType,
    MetricTypeLabel,
    ValueType,
} from '../../../../../pages/geospatial-viewer/model/metric';
import {FormatsService} from '@core/utils';
import {MapColoursService} from '../renderers/map-colours.service';
import {GeospatialViewerService} from '../../../../../pages/geospatial-viewer/api/geospatial-viewer.service';
import {StudiesStore} from '@store/common/studies.store';
import {Injectable} from '@angular/core';
import {
    GeospatialAssetPopupRequest,
    GeospatialAssetPopupResponse,
    Unit,
} from '../../../../../pages/geospatial-viewer/model/api';
import {NbThemeService} from '@nebular/theme';
import {WorkflowType} from '@core/interfaces/engin/workflow';
import {LayersService} from '../../../../../pages/geospatial-viewer/layers/layers.service';
import {forkJoin} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {MetricLayer} from '../../../../../pages/geospatial-viewer/layers/layer';

@Injectable()
export class AssetPopupService {
    constructor(
        private studiesStore: StudiesStore,
        private geospatialViewerService: GeospatialViewerService,
        private nbThemeService: NbThemeService,
        private layerService: LayersService,
    ) {}
    /*
     * Render popup for single asset feature. Works for point or line features equivalently.
     */
    getAssetPopupFunction(feature): Promise<any> {
        const assetId: string = feature.graphic.attributes.id;
        const assetClassCode: string = feature.graphic.attributes.classCode;
        const studyId: number = this.studiesStore.getActiveStudyIdByType(WorkflowType.STUDY);
        const currentTheme = this.nbThemeService.currentTheme;

        return this.layerService.metricLayers$
            .pipe(
                take(1),
                switchMap((currentMetricLayers) => {
                    const popupRequests: GeospatialAssetPopupRequest[] = currentMetricLayers.map(
                        (layer: MetricLayer) => {
                            const measure: MeasureType = layer.layerForm.controls.measure.value.code;
                            const metric: MetricType = layer.layerForm.controls.metric.value.code;
                            const valueType: ValueType = layer.layerForm.controls.metric.value.valueType;

                            return {
                                measureType: measure,
                                metricType: metric,
                                valueType: valueType,
                                assetId: assetId,
                                assetClassCode: assetClassCode,
                            };
                        },
                    );

                    return forkJoin(
                        popupRequests.map((popupRequest) =>
                            this.geospatialViewerService.getAssetPopupInfo(studyId, popupRequest),
                        ),
                    ).pipe(map((metricResponses) => this.renderAssetPopup(feature, metricResponses, currentTheme)));
                }),
            )
            .toPromise();
    }

    renderAssetPopup(feature: any, metrics: GeospatialAssetPopupResponse[], theme: string) {
        const info = metrics[0];
        const interventionCost: number = info.interventionCost;
        const bcRatio: number = info.bcRatio;
        const id: string = info.assetId;
        const className: string = info.assetClass;
        const costUnit: Unit = info.costUnit;
        const displayCategory: string = feature.graphic.attributes.displayCategory;
        const displayColour = MapColoursService.getMapCategoryColour(displayCategory, theme).color;
        const formattedCostValue = FormatsService.prepareCostValue(interventionCost, costUnit.prefix, costUnit.suffix);
        // layer = 'c' is street view; 'cbll' is lat/long for streetview; 'll' is lat/long for default map
        const lat = feature?.graphic?.geometry?.latitude || info?.latitude;
        const long = feature?.graphic?.geometry?.longitude || info?.longitude;
        const streetViewUrl = `http://maps.google.com/maps?layer=c&cbll=${lat},${long}`;
        const mapsUrl = `http://maps.google.com/maps?ll=${lat},${long}`;
        const mainLabelStyle =
            'font-family: Roboto; font-size: 14px; font-style: normal; font-weight: 700; line-height: 20px;';
        const subLabelStyle =
            'font-size: 14px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 8px; width: 50%;';
        const valueStyle = 'font-size: 14px; font-style: normal; font-weight: 400; line-height: 16px; width: 50%;';
        const divStyle = 'display: flex; justify-content: space-between;';
        const extraHtml =
            lat && long
                ? `
        <div class='row justify-content-start' style="margin-top: 24px">
            <div class='col-3 p-0'>
            <p style="${mainLabelStyle}">External links: </p>
            </div>
            <a class='col-4 p-0' href="${streetViewUrl}" target="_blank" style="display: flex; padding: 0">
              Google Street View
              <calcite-icon style="margin-left: 4px; width: 16px; height: 16px; min-height: 0; min-width: 0;" icon="launch"/>
            </a>
            <a class='col-4 p-0' href="${mapsUrl}" target="_blank" style="display: flex; padding: 0; margin-left: 24px">
              Google Maps
              <calcite-icon style="margin-left: 4px; width: 16px; height: 16px; min-height: 0; min-width: 0;" icon="launch"/>
            </a>
        </div>
        `
                : '';

        const metricLayerContent = metrics
            .map((metric) => {
                const layerMeasureName: string = MeasureTypeLabel[metric.measureType];
                const layerMetricName: string = MetricTypeLabel[metric.metricType];
                const layerData: string | number = metric.data;
                let formattedValue = layerData;
                const layerMetricDataUnit: Unit = metric.dataUnit;

                const isNumeric = !isNaN(Number(metric.data));
                if (isNumeric) {
                    formattedValue = FormatsService.prepareValue(
                        Number(layerData),
                        layerMetricDataUnit.prefix,
                        layerMetricDataUnit.suffix,
                    );
                }

                return `<div style="${divStyle}">
                <p style="${subLabelStyle}">${layerMeasureName}: ${layerMetricName}</p>
                 <p style="${valueStyle}">${formattedValue}
                 ${
                     !isNumeric
                         ? `<svg height="15" width="15" style="vertical-align:baseline; "><circle cx="9" cy="10" r="5" fill="${displayColour}"/></svg>`
                         : ``
                 }
                </p>
                </div>`;
            })
            .join('');

        const div = document.createElement('div');
        div.innerHTML = `
  <div style="display: flex;align-items: center;margin-top: 7px; gap: 10px;">
    <svg height="8" width="8" style="vertical-align:middle; "><circle cx="4" cy="4" r="3" fill="#000000"/></svg>

                            <p style="font-size: 14px; font-style: normal; font-weight: 700; line-height: 20px; display: inline-block; margin: 0 0 0 0">
                             ${className}: ${id}  </p>

                        </div>
                                <div class='container assetPopup' style="margin-top: 17px">

                                    <div class="row">
                                        <p style="${mainLabelStyle}">Assets Details</p>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 p-0">
                                            <div style="${divStyle}">
                                            <p style="${subLabelStyle}">Asset Subclass</p>
                                            <p style="${valueStyle}">-</p>
                                            </div>

                                        <div style="${divStyle}">
                                            <p style="${subLabelStyle}">Circuit</p>
                                            <p style="${valueStyle}">-</p>
                                            </div>

                                        <div style="${divStyle}">
                                            <p style="${subLabelStyle}">PRID</p>
                                            <p style="${valueStyle}">-</p>
                                            </div>

                                         <div style="${divStyle}">
                                                   <p style="${subLabelStyle}">IRID</p>
                                                   <p style="${valueStyle}">-</p>
                                                  </div>
                                        </div>
                                    </div>

                                    <div class="row" style="padding-top:16px">
                                        <p style="${mainLabelStyle}">Metrics</p>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 p-0">
                                        <div style="${divStyle}">
                                            <p style="${subLabelStyle}">10-year risk B/C</p>
                                            <p style="${valueStyle}">${bcRatio.toFixed(3)}</p>
                                            </div>
                                             <div style="${divStyle}">
                                            <p style="${subLabelStyle}">Replacement Value</p>
                                            <p style="${valueStyle}">${formattedCostValue}</p>
                                            </div>
                                            ${metricLayerContent}
                                        </div>
                                    </div>

                                    ${extraHtml}
                              </div>`;

        return div;
    }
    defaultRenderAssetPopup(feature) {
        const assetId: string = feature.graphic.attributes.id;
        const div = document.createElement('div');
        div.innerHTML = `<div class='container'>
                            <div class='row'>
                                <div class="col">
                                    <p class="m-0 p-0 text-center">${assetId}</p>
                                </div>
                            </div>
                        </div>`;
        return div;
    }
}
