import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {DocumentAssetImage, FileFormatType, FileFormatTypeLabels} from '@core/interfaces/common/document';
import {debounceTime, distinctUntilChanged, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';

export type TagOptionsCallback = (searchString?: string) => Observable<string[]>;

@Component({
    selector: 'ngx-upload-file-dialog',
    templateUrl: './upload-file-dialog.component.html',
    styleUrls: ['./upload-file-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFileDialogComponent extends Unsubscribable implements OnInit {
    @Input() acceptedFileTypes: string[];
    @Input() file?: DocumentAssetImage | any;
    @Input() getTagOptionsCallback: TagOptionsCallback;

    public tagOptions = new BehaviorSubject<string[]>([]);
    readonly tagOptions$ = this.tagOptions.asObservable();

    fileForm: FormGroup = this.fb.group({
        fileName: [
            null,
            [Validators.required, Validators.maxLength(255), Validators.pattern(/^[a-zA-Z0-9!\-_.*'() ]+$/)],
        ],
        tag: [null, [Validators.required, Validators.maxLength(100)]],
        description: [null, [Validators.maxLength(255)]],
        url: [null, Validators.required],
        file: [null, Validators.required],
        size: [null],
    });

    saveBtnPressed = false;

    FileFormatTypeLabels = FileFormatTypeLabels;

    constructor(
        protected ref: NbDialogRef<UploadFileDialogComponent>,
        private fb: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.file) {
            if (this.file.fileName.lastIndexOf('.') != -1) {
                this.file.fileName = this.file.fileName.substring(0, this.file.fileName.lastIndexOf('.'));
            }

            this.fileForm.patchValue({
                ...this.file,
                file: {
                    fileFormat: this.file.fileFormat,
                    fileType: this.file.fileType,
                    fileName: this.file.fileName,
                    size: this.file.size,
                },
            });
        }

        this.fileForm
            .get('tag')
            .valueChanges.pipe(
                takeUntil(this.unsubscribe$),
                startWith(''),
                debounceTime(500),
                distinctUntilChanged(),
                switchMap((searchString) => this.getTagOptionsCallback(searchString)),
            )
            .subscribe((optionList: string[]) => this.tagOptions.next(optionList));
    }

    unstageImage() {
        this.fileForm.patchValue({file: null});
        this.fileForm.patchValue({url: null});
    }

    stageImage(file): void {
        this.fileForm.patchValue({
            file: file,
        });
    }

    onFileAdded(file) {
        const fr = new FileReader();
        fr.readAsDataURL(file.file);
        fr.onload = (e) => {
            this.fileForm.patchValue({url: e.target.result});

            this.changeDetectorRef.detectChanges();
        };

        if (file.name.lastIndexOf('.') != -1) {
            file.name = file.name.substring(0, file.name.lastIndexOf('.'));
        }

        this.stageImage({
            ...file,
            fileName: file.name,
            documentType: 'ASSET',
        });

        this.fileForm.patchValue({
            fileName: file.name,
            size: (file.size / 1048576).toFixed(2),
        });
    }

    cancel() {
        this.ref.close();
    }

    save() {
        if (this.fileForm.invalid) {
            this.saveBtnPressed = true;
            this.fileForm.markAllAsTouched();
            return;
        }
        this.fileForm.get('tag').setValue(this.fileForm.get('tag').value.trim(), {emitEvent: false});
        this.ref.close(this.fileForm.value);
    }

    public documentIsImage(fileFormat: FileFormatType) {
        switch (fileFormat) {
            case FileFormatType.JPG:
            case FileFormatType.JPEG:
            case FileFormatType.PNG:
                return true;
            default:
                return false;
        }
    }

    public documentIsData(fileFormat: FileFormatType) {
        switch (fileFormat) {
            case FileFormatType.CSV:
            case FileFormatType.MS_EXCEL:
            case FileFormatType.PDF:
                return true;
            default:
                return false;
        }
    }
}
