import {Observable} from 'rxjs';
import {
    APIResponse,
    Filter,
    SimpleMultiSeries,
    SimpleUnit,
    SimpleUnitType,
} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {KpiData} from '@theme/components/chart-kpi/chart-kpi.component';
import {KpiType} from '@core/interfaces/engin/program-management/segment';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';
import {OutcomeMaintainType, PmDataRequest} from '@core/interfaces/engin/program-management/pm-reporting';
import {PmAssetDto, Project, ProjectAssetDto, ProjectDto} from '@core/interfaces/engin/program-management/project';

/*
 * Response
 */
export interface Alternative {
    id: number;
    createdOn: Date;
    updatedOn: Date;
    name: string;
    description: string;
    projectId: number;
    createdType: AlternativeSourceType;
    lastModifiedType: AlternativeSourceType;
    alternativeSelected: boolean;
}

export interface AlternativeDto extends Alternative {
    workflowItemId: number;
    assetCountTotal: number;
    assetCountPoint: number;
    assetCountLinear: number;
    assetLength: number;
    lengthUnit: SimpleUnit;
    totalCost: number;
    tcoTotal: number;
    tcoWithoutCost: number;
    benefitGross: number;
    benefitNet: number;
    ratioNetBenefitCost: number;
    countPastTul: number;
    countWorstHealth: number;
    riskCurrent: number;
    ciCurrent: number;
    chiCurrent: number;
}

export interface AlternativeFullDto extends AlternativeDto {
    interventions: InterventionSummaryItem[];
    outcomesComparison: MetricComparisonItem[];
}

export interface InterventionSummaryItem {
    label: string;
    countReplace: number;
    countRepair: number;
    countDoNothing: number;
    countInstall: number;
    countTotal: number;
}

export interface MetricComparisonItem {
    outcomeType: AlternativeOutcomeType;
    label: string;
    unit: SimpleUnit;
    baseAlternativeValue: number;
    currentAlternativeValue: number;
    deltaValue: number;
    deltaUnit: SimpleUnit;
    deltaValueGood: boolean;
    skipDelta: boolean;
}

export interface AlternativeAssetDto extends ProjectAssetDto {
    alternativeId: number;
    interventionType: AssetInterventionType;
    interventionYear: number;
    renewalParams: AssetRenewalParams;
    installParams: AssetInstallParams;
}

export interface AlternativeCostResponse {
    fixedList: AlternativeCostItem[];
    userList: AlternativeCostItem[];
    costUnit: SimpleUnit;
    // TODO: chart;
}

export interface AlternativeCostItem {
    parameterName: string;
    costValue: number;
}

export interface AlternativeMetricResponse {
    fixedList: AlternativeMetricItem[];
    userList: AlternativeMetricItem[];
    costUnit: SimpleUnit;
    // TODO: chart;
}

export interface AlternativeMetricItem {
    metricType: AlternativeMetricType;
    parameterName: string;
    metricUnit: SimpleUnit;
    valueBefore: number;
    valueAfter: number;
    valueDelta: number;
}

/*
 * Request
 */
export interface AlternativeCreateDto {
    name: string;
    description: string;
    projectId: number;
    scenario: AlternativeScenarioType;
    workflowItemId: number;
}

export class AlternativeUpdateDto {
    name: string;
    description: string;

    static fromAlternative(a: AlternativeDto): AlternativeUpdateDto {
        return {
            name: a.name,
            description: a.description,
        } as AlternativeUpdateDto;
    }
}

export interface UpdateInterventionRequest extends PmDataRequest {
    existingAssetChanges: AlternativeAssetDto[];
    existingInstallChanges: AssetInstallParams[];
    newInstalls: AssetInstallParams[];
}

export interface ResetAlternativeRequest extends PmDataRequest {
    scenario: AlternativeScenarioType;
}

export interface AlternativeInterventionDto {
    id: number;
    alternativeId: number;
    interventionType: AssetInterventionType;
    assetId: string;
    interventionYear: number;
    interventionCost: number;
    renewalParams: AssetRenewalParams;
    installParams: AssetInstallParams;
}

export interface AssetRenewalParams {
    assetId: string;
}

export interface AssetInstallParams {
    assetClassCode: string;
    assetClass: string;
    relatedAssetId: string;
    relatedAssetClassCode: string;
}

/*
 * Enums
 */
export enum AlternativeSourceType {
    USER = 'USER',
    SYSTEM = 'SYSTEM',
}

export enum AlternativeOutcomeType {
    COUNT_ASSET_POINT = 'COUNT_ASSET_POINT',
    LENGTH_ASSET = 'LENGTH_ASSET',
    TOTAL_COST = 'TOTAL_COST',
    RISK_CURRENT = 'RISK_CURRENT',
}

export const AlternativeOutcomeTypeLabel = {
    [AlternativeOutcomeType.COUNT_ASSET_POINT]: 'Count of Point Assets',
    [AlternativeOutcomeType.LENGTH_ASSET]: 'Linear Length',
    [AlternativeOutcomeType.TOTAL_COST]: 'Total Cost',
    [AlternativeOutcomeType.RISK_CURRENT]: 'Risk (Current)',
};

export enum AlternativeScenarioType {
    NONE = 'NONE',
    REPLACE_ALL_ECONOMIC_EOL = 'REPLACE_ALL_ECONOMIC_EOL',
    REPLACE_ALL_ECONOMIC_EOL_MORATORIUM_10_YEARS = 'REPLACE_ALL_ECONOMIC_EOL_MORATORIUM_10_YEARS',
    REPLACE_ALL_NOW = 'REPLACE_ALL_NOW',
}

export const AlternativeScenarioTypeLabel = {
    [AlternativeScenarioType.NONE]: 'None',
    [AlternativeScenarioType.REPLACE_ALL_ECONOMIC_EOL]: 'Replace at Economic EOL',
    [AlternativeScenarioType.REPLACE_ALL_ECONOMIC_EOL_MORATORIUM_10_YEARS]: 'Moratorium for: 10 years',
    [AlternativeScenarioType.REPLACE_ALL_NOW]: 'Replace all (now)',
};

export enum AssetInterventionType {
    NONE = 'NONE',
    REPLACE = 'REPLACE',
    REPAIR = 'REPAIR',
    INSTALL = 'INSTALL',
}

export const AssetInterventionTypeLabel = {
    [AssetInterventionType.NONE]: 'None',
    [AssetInterventionType.REPLACE]: 'Replace',
    [AssetInterventionType.REPAIR]: 'Repair',
    [AssetInterventionType.INSTALL]: 'Install',
};

export enum AlternativeMetricType {
    CUSTOMER_INTERRUPTED = 'CUSTOMER_INTERRUPTED',
    CUSTOMER_HOURS_INTERRUPTED = 'CUSTOMER_HOURS_INTERRUPTED',
    RISK_TOTAL = 'RISK_TOTAL',
}

export const AlternativeMetricTypeLabel = {
    [AlternativeMetricType.CUSTOMER_INTERRUPTED]: 'CI',
    [AlternativeMetricType.CUSTOMER_HOURS_INTERRUPTED]: 'CHI',
    [AlternativeMetricType.RISK_TOTAL]: 'Total Risk',
};

export enum InstallAssetType {
    RECLOSER = 'RECLOSER',
    SWITCH = 'SWITCH',
}

export const InstallAssetTypeLabel = {
    [InstallAssetType.RECLOSER]: 'Recloser',
    [InstallAssetType.SWITCH]: 'Switch',
};

export abstract class AlternativeService {
    /*
     * APIs for Alternatives model
     */
    abstract createAlternative(alternative: AlternativeCreateDto): Observable<APIResponse<Alternative>>;

    abstract editAlternativeDetails(
        alternativeId: number,
        alternative: AlternativeUpdateDto,
    ): Observable<APIResponse<Alternative>>;

    abstract deleteAlternative(alternativeId: number): Observable<APIResponse<boolean>>;

    abstract getAlternativeList(params?: Filter[], pageSize?: number): Observable<Alternative[]>;

    abstract updateAlternativeInterventions(
        alternativeId: number,
        updateDto: UpdateInterventionRequest,
    ): Observable<APIResponse<AlternativeFullDto>>;

    abstract resetAlternative(
        alternativeId: number,
        updateDto: ResetAlternativeRequest,
    ): Observable<APIResponse<AlternativeFullDto>>;

    /*
     * APIs for AlternativeDto
     */
    abstract getAlternativeDto(alternativeId: number, request: PmDataRequest): Observable<APIResponse<AlternativeDto>>;

    abstract getAlternativeDtoList(
        request: PmDataRequest,
        params?: Filter[],
        pageSize?: number,
    ): Observable<AlternativeDto[]>;

    abstract getAlternativeDtoListCsv(request: PmDataRequest, params?: Filter[]): Observable<APIResponse<DownloadURL>>;

    abstract getAlternativeDtoListTable(request: PmDataRequest, params?: Filter[]): Observable<DataSource>;

    /*
     * APIs for AlternativeFullDto
     */
    abstract getAlternativeDtoFull(
        alternativeId: number,
        request: PmDataRequest,
    ): Observable<APIResponse<AlternativeFullDto>>;

    /*
     * APIs for AlternativeAssetDto
     */
    abstract getAlternativeAssetsList(
        alternativeId: number,
        request: PmDataRequest,
        filters?: Filter[],
        pageSize?: number,
    ): Observable<AlternativeAssetDto[]>;

    abstract getAlternativeAssetsListCsv(
        alternativeId: number,
        request: PmDataRequest,
        params?: string,
    ): Observable<APIResponse<DownloadURL>>;

    abstract getAlternativeAssetsListTable(alternativeId: number, request: PmDataRequest): Observable<DataSource>;

    /*
     * APIs related to analysis of costs and benefits
     */
    abstract getCostResults(
        alternativeId: number,
        request: PmDataRequest,
    ): Observable<APIResponse<AlternativeCostResponse>>;

    abstract getBenefitResultsByType(
        alternativeId: number,
        benefitType: AlternativeMetricType,
        request: PmDataRequest,
    ): Observable<APIResponse<AlternativeMetricResponse>>;

    /*
     * Other support APIs
     */
    abstract getInterventionOptions(): Observable<APIResponse<AssetInterventionType[]>>;

    abstract validateAlternativeName(projectId: number, alternativeName: string): Observable<boolean>;

    /*
     * Helpers - call other APIs
     */
    abstract getAlternativeDtoByProjectTable(projectId: number, request: PmDataRequest): Observable<DataSource>;
}
