import * as FileSaver from 'file-saver';
import {ECharts} from 'echarts';

export enum ECHARTS_TYPE {
    CUSTOM_BAR_STACKED = 1, // e.g., analyzer EOL metrics
    BAR_V, // Temporary: planner, project view
    CUSTOM_COL_STACKED, // e.g., chartData.data.name
    COL_STACKED_CLUSTERED, // e.g., optimizer, forecaster
    PIE, // e.g., analyzer EOL metrics
    DUAL_PIE, // e.g., ACA Data Gaps
    LINE, // e.g., Data audit tool
    CUSTOM_LINE, // e.g., connectivity analysis (Load Profile)
    CUSTOM_BAR, // e.g., connectivity analysis (Load Profile)
    BAR, // e.g., Data audit tool
    SCATTER, // e.g. Evaluation data audit tool, LIVE dashboard > Sf6 gas leak
    SCATTER_ASSET_MAINTENANCE, // e.g. Asset maintenance
    SINGLE_SERIES_BAR_LINE, // e.g. forecaster (station availability, line availability)
    MULTI_LINE, // e.g., Data audit tool
    TIMESERIES, // e.g., LIVE dense time-series (line or area)
    LOAD_FORECAST_LINE, // e.g. Load Forecast page
    LINE_WITH_X_AXIS_DATES, // xAxis Data is shown as rows, not columns e.g. Load Profile Analysis -> Linked meters page
}

export interface ChartDownloadData {
    header: any[];
    data: any[];
}

export class ChartDataDownloadService {
    // Prepare chart data for download then send to browser
    static prepareAndDownloadChartData(options: any, chartType: ECHARTS_TYPE, filename: string) {
        const outputHeader = [];
        const outputObjectArray = [];
        let xAxisName, yAxisName;

        switch (chartType) {
            case ECHARTS_TYPE.CUSTOM_BAR_STACKED: {
                // Create header from series names
                outputHeader.push('Category');
                for (let i = 0; i < options.series.length; i++) {
                    outputHeader.push(options.series[i].name);
                }

                // Add data rows from each independent series/bar
                if (options.yAxis[0].data && options.yAxis[0].data != null) {
                    // Multi bar has yAxis data element
                    for (let yIndex = 0; yIndex < options.yAxis[0].data.length; yIndex++) {
                        const tempDataEntry = {};
                        tempDataEntry['Category'] = options.yAxis[0].data[yIndex];

                        // Look for custom data object "origValue" in cases when custom formatting was used
                        for (let i = 0; i < options.series.length; i++) {
                            tempDataEntry[`field_${i}`] = options.series[i].data[yIndex].origValue
                                ? options.series[i].data[yIndex].origValue
                                : options.series[i].data[yIndex].value;
                        }
                        outputObjectArray.push(tempDataEntry);
                    }
                } else {
                    // Single bar - no yAxis data element
                    const tempDataEntry = {};
                    tempDataEntry['Category'] = 'All';
                    for (let i = 0; i < options.series.length; i++) {
                        tempDataEntry[`field_${i}`] = options.series[i].data[0].origValue
                            ? options.series[i].data[0].origValue
                            : options.series[i].data[0].value;
                    }
                    outputObjectArray.push(tempDataEntry);
                }

                break;
            }
            case ECHARTS_TYPE.BAR_V: {
                outputHeader.push('Alternative');
                outputHeader.push('Total Cost of Ownership [$M]');
                for (let xIndex = 0; xIndex < options.xAxis[0].data.length; xIndex++) {
                    const tempDataEntry = {};
                    tempDataEntry['Alternative'] = options.xAxis[0].data[xIndex];
                    tempDataEntry['Total Cost of Ownership [$M]'] = options.series[0].data[xIndex].value;
                    outputObjectArray.push(tempDataEntry);
                }
                break;
            }
            case ECHARTS_TYPE.COL_STACKED_CLUSTERED: {
                // Create header row
                xAxisName = options.xAxis[0].name;
                outputHeader.push(xAxisName);
                for (let seriesIndex = 0; seriesIndex < options.series.length; seriesIndex++) {
                    outputHeader.push(options.series[seriesIndex].name);
                }

                /*
                 * Iterate over x-axis values and pull values for each series row-by-row.
                 * - Stacked series objects show with different names in the series list
                 * - Pull custom data format for series names vs. codes, and handle total lines
                 */
                for (let xIndex = 0; xIndex < options.xAxis[0].data.length; xIndex++) {
                    const tempDataEntry = {};
                    tempDataEntry[`field_${xAxisName}`] = !isNaN(Number(options.xAxis[0].data[xIndex]))
                        ? Number(options.xAxis[0].data[xIndex])
                        : options.xAxis[0].data[xIndex];

                    for (let seriesIndex = 0; seriesIndex < options.series.length; seriesIndex++) {
                        // Push data; for totals, read the second data element
                        if (
                            options.series[seriesIndex].name.includes('total') &&
                            Array.isArray(options.series[seriesIndex].data[xIndex])
                        ) {
                            tempDataEntry[`field_${seriesIndex}`] = options.series[seriesIndex].data[xIndex][1];
                        } else {
                            tempDataEntry[`field_${seriesIndex}`] = options.series[seriesIndex].data[xIndex];
                        }
                    }
                    outputObjectArray.push(tempDataEntry);
                }
                break;
            }
            case ECHARTS_TYPE.CUSTOM_COL_STACKED: {
                // Create header row
                xAxisName = options.xAxis[0].name;
                outputHeader.push(xAxisName);
                for (let seriesIndex = 0; seriesIndex < options.series.length; seriesIndex++) {
                    outputHeader.push(options.series[seriesIndex].name);
                }

                // Iterate over x-axis values and pull values for each series row-by-row
                for (let xIndex = 0; xIndex < options.xAxis[0].data.length; xIndex++) {
                    const tempDataEntry = {};
                    tempDataEntry[`field_${xAxisName}`] = !isNaN(Number(options.xAxis[0].data[xIndex]))
                        ? Number(options.xAxis[0].data[xIndex])
                        : options.xAxis[0].data[xIndex];

                    for (let seriesIndex = 0; seriesIndex < options.series.length; seriesIndex++) {
                        // Push data; for totals, read the second data element
                        if (
                            options.series[seriesIndex].name.includes('total') &&
                            Array.isArray(options.series[seriesIndex].data[xIndex])
                        ) {
                            tempDataEntry[`field_${seriesIndex}`] = options.series[seriesIndex].data[xIndex][1];
                        } else {
                            tempDataEntry[`field_${seriesIndex}`] = options.series[seriesIndex].data[xIndex]?.origValue
                                ? options.series[seriesIndex].data[xIndex]?.origValue
                                : options.series[seriesIndex].data[xIndex];
                        }
                    }

                    outputObjectArray.push(tempDataEntry);
                }
                break;
            }
            case ECHARTS_TYPE.PIE: {
                outputHeader.push('Category');
                outputHeader.push('Value');

                for (let i = 0; i < options.series[0].data.length; i++) {
                    const tempDataEntry = {};
                    tempDataEntry['Category'] = options.series[0].data[i].name;
                    tempDataEntry['Cost'] = options.series[0].data[i].value;
                    outputObjectArray.push(tempDataEntry);
                }
                break;
            }
            case ECHARTS_TYPE.DUAL_PIE: {
                outputHeader.push('Category');
                outputHeader.push('Value');
                options.series.map((item) => {
                    item.data.map((data) => {
                        const tempDataEntry = {};
                        tempDataEntry['Category'] = data.name;
                        tempDataEntry['Cost'] = data.value;
                        outputObjectArray.push(tempDataEntry);
                    });
                });
                break;
            }
            case ECHARTS_TYPE.CUSTOM_LINE:
            case ECHARTS_TYPE.CUSTOM_BAR:
            case ECHARTS_TYPE.LINE: {
                const xAxisValue = options.xAxis.data;
                outputHeader.push('Category');
                const tempDataArray = [];
                xAxisValue.map((xAxis, index) => {
                    outputHeader.push(xAxis);
                    options.series.map((item) => {
                        if (item.id) {
                            const tempDataObj = {};
                            tempDataObj['Category'] = item.name;
                            tempDataObj[xAxis] = item.data[index].formattedValue || item.data[index];
                            tempDataArray.push(tempDataObj);
                        }
                    });
                });

                // merge object by same Category
                const result = {};
                tempDataArray.forEach((item) => (result[item.Category] = {...result[item.Category], ...item}));
                Object.values(result).map((res) => {
                    outputObjectArray.push(res);
                });
                break;
            }
            case ECHARTS_TYPE.LOAD_FORECAST_LINE: {
                const xAxisValue = options.xAxis.data;
                outputHeader.push('Category');
                const tempDataArray = [];
                xAxisValue.map((xAxis, index) => {
                    outputHeader.push(xAxis);
                    options.series.map((item) => {
                        const tempDataObj = {};
                        let tempDataObjValue;
                        if (item.data?.length > 0) {
                            tempDataObjValue = item.data[index]?.formattedValue
                                ? item.data[index]?.formattedValue
                                : item.data[index];
                        } else {
                            if (item.markLine) {
                                tempDataObjValue = Object.values(item.markLine.data[0])[0];
                            } else {
                                tempDataObjValue = '';
                            }
                        }

                        tempDataObj['Category'] = item.name;
                        tempDataObj[xAxis] = tempDataObjValue;
                        tempDataArray.push(tempDataObj);
                    });
                });

                // merge object by same Category
                const result = {};
                tempDataArray.forEach((item) => (result[item.Category] = {...result[item.Category], ...item}));
                Object.values(result).map((res) => {
                    outputObjectArray.push(res);
                });
                break;
            }
            case ECHARTS_TYPE.LINE_WITH_X_AXIS_DATES: {
                // Get the xAxis values (these will be the row headers)
                const xAxisValue = options.xAxis?.data || options.xAxis[0]?.data || [];

                // Start with the header, 'Date' as the first column, followed by the series names
                outputHeader.push('Date');
                options.series.forEach((item) => {
                    // we're working with the series associated with the first xAxis
                    if (!item.xAxisIndex) {
                        outputHeader.push(item.name); // Add each series name to the header
                    }
                });

                // Initialize an array to store each row of the output
                const tempDataArray = [];

                // Loop through each xAxis value (representing the rows)
                xAxisValue.forEach((xAxis, index) => {
                    // Create a row starting with the xAxis value (the 'Date')
                    const row = {Date: xAxis};

                    // For each series, add the corresponding data point for the current xAxis value
                    options.series.forEach((item) => {
                        // Only process series associated with the first xAxis (xAxisIndex: 0)
                        if (!item.xAxisIndex) {
                            let value;
                            if (item.data?.length > 0) {
                                value = item.data[index]?.formattedValue
                                    ? item.data[index]?.formattedValue
                                    : item.data[index];
                            } else if (item.markLine) {
                                value = Object.values(item.markLine.data[0])[0];
                            } else {
                                value = ''; // Default empty value if no data
                            }

                            // Set the value for this series in the current row
                            row[item.name] = value;
                        }
                    });

                    // Push the row into the data array
                    tempDataArray.push(row);
                });

                // Convert the rows to the final output array
                tempDataArray.forEach((row) => {
                    outputObjectArray.push(row);
                });

                break;
            }

            case ECHARTS_TYPE.SCATTER: {
                const xAxisValue = options.series[0].data;
                outputHeader.push('Category');

                const tempDataArray = [];

                // Loop through xAxis values
                xAxisValue.forEach((xAxis, index) => {
                    outputHeader.push(xAxis[0]);

                    // Loop through series data to build the data array
                    options.series.forEach((item) => {
                        if (item.id) {
                            const tempDataObj = {};
                            tempDataObj['Category'] = item.name;
                            tempDataObj[xAxis[0]] = item.data[index][1]; // Assign corresponding y-axis value
                            tempDataArray.push(tempDataObj);
                        }
                    });
                });

                // Merge objects by the same Category
                const result = tempDataArray.reduce((acc, item) => {
                    acc[item.Category] = {...acc[item.Category], ...item}; // Merge data for the same category
                    return acc;
                }, {});

                // Push the final merged objects into the output array
                outputObjectArray.push(...Object.values(result));
                break;
            }

            case ECHARTS_TYPE.SCATTER_ASSET_MAINTENANCE: {
                const rows = options.xAxis[0].data; // X-axis data (quarters)
                const years = options.xAxis[1].data; // Year data from the second X-axis
                const xAxisName = 'Time Period'; // Label for the first column

                const seriesList = options.series.map((item) => item.id && item.name); // Get the names of all series

                // The first column will be 'Time Period', followed by the series names as column headers
                outputHeader.push(xAxisName, ...seriesList);

                const tempDataArray = [];

                // Loop through each X value (quarter) and gather corresponding Y values (inspections) for each series
                rows.forEach((quarter, index) => {
                    if (!quarter || !years[index]) return; // Skip if no valid quarter or year data

                    const tempDataObj = {};
                    tempDataObj[xAxisName] = `${years[index]} - Q${quarter}`; // Combine year and quarter for the 'Time Period' column

                    // Loop through each series and get the corresponding Y value for this X value
                    options.series.forEach((seriesItem) => {
                        if (seriesItem.id) {
                            const value = seriesItem.data[index] ? seriesItem.data[index] : null; // Y value or null if not available
                            tempDataObj[seriesItem.name] = value;
                        }
                    });

                    tempDataArray.push(tempDataObj);
                });

                // Add the rows to the final output array for the CSV
                tempDataArray.forEach((item) => {
                    outputObjectArray.push(item);
                });

                break;
            }
            case ECHARTS_TYPE.BAR: {
                const yAxisValue = options.yAxis.data;
                outputHeader.push('Category');
                const tempDataArray = [];
                yAxisValue.map((yAxis, index) => {
                    outputHeader.push(yAxis);
                    options.series.map((item) => {
                        if (item.id) {
                            const tempDataObj = {};
                            tempDataObj['Category'] = item.name;
                            tempDataObj[yAxis] = item.data[index];
                            tempDataArray.push(tempDataObj);
                        }
                    });
                });

                // merge object by same Category
                const result = {};
                tempDataArray.forEach((item) => (result[item.Category] = {...result[item.Category], ...item}));
                Object.values(result).map((res) => {
                    outputObjectArray.push(res);
                });

                break;
            }
            case ECHARTS_TYPE.SINGLE_SERIES_BAR_LINE: {
                /*
                 * Chart type: bar
                 * Number of series: 1
                 * Extract header: (xAxis name (default: Category), series name (default: yAxis name))
                 * Extract body: one row per element of series.data
                 */
                // Create header row
                const xAxis = options?.xAxis[0] || options?.xAxis;
                const yAxis = options?.yAxis[0] || options?.yAxis;
                xAxisName = xAxis?.name || 'Category';
                yAxisName = yAxis?.name || 'Value';
                outputHeader.push(xAxisName);
                outputHeader.push(options.series[0]?.name || yAxisName);

                // Iterate over x-axis values and pull values from single series row-by-row
                for (let ind = 0; ind < xAxis.data.length; ind++) {
                    const tempDataEntry = {};
                    tempDataEntry[`field_${xAxisName}`] = !isNaN(Number(xAxis.data[ind]))
                        ? Number(xAxis.data[ind])
                        : xAxis.data[ind];
                    tempDataEntry[`field_${0}`] = options.series[0].data[ind];
                    outputObjectArray.push(tempDataEntry);
                }
                break;
            }
            case ECHARTS_TYPE.TIMESERIES: {
                /*
                 * Options:
                 * - Options.xAxis has no data
                 * - Options.series has one or more elements; each element has data array where each element is time/value pairs
                 * Output:
                 * - First column: header "date" or "time" from Options.xAxis.name
                 * - One column per series
                 * - One row per x-Axis value
                 */
                const multiSeries = options.series;
                const xAxisData = options.series[0]?.data?.map((e) => e[0]);
                const xAxisHeader = options.xAxis?.name ? options.xAxis?.name : 'Date/Time';
                // Create header
                outputHeader.push(xAxisHeader);
                multiSeries.forEach((series) => {
                    const seriesName = series.name;
                    outputHeader.push(seriesName);
                });
                // Populate rows one one at a time
                xAxisData.forEach((val, index) => {
                    const tempDataObj = {};
                    tempDataObj[xAxisHeader] = val;
                    multiSeries.forEach((series) => {
                        const seriesName = series.name;
                        tempDataObj[seriesName] = series.data[index][1]; // [date/time, value]
                    });
                    outputObjectArray.push(tempDataObj);
                });
                break;
            }
        }

        // Send data to be downloaded to browser
        const customData: ChartDownloadData = {
            header: outputHeader,
            data: outputObjectArray,
        };

        ChartDataDownloadService.downloadChartData(customData, filename);
    }

    // Prepare CSV data and send to browser
    static downloadChartData(customData: ChartDownloadData, fileName: string) {
        let headerRow: string[] = customData.header;
        const arrData: any[] = customData.data;
        let row = arrData[0];
        let dataKey = Object.keys(row || []);
        const replacer = (key, value) => (value === null ? '' : value);

        // Create header row if it was not directly provided
        if (headerRow === null) {
            headerRow = dataKey;
        } else if (dataKey.length === headerRow.length && headerRow.every((element) => element in row)) {
            dataKey = headerRow;
        }

        // Create CSV data for download
        const csvData = arrData.map((row) =>
            dataKey
                .map((fieldName) => {
                    return JSON.stringify(row[fieldName], replacer);
                })
                .join(','),
        );
        csvData.unshift(headerRow.join(','));

        // Create CSV file and send to browser
        const csvArray = csvData.join('\r\n');
        const blob = new Blob([csvArray], {type: 'text/csv'});
        FileSaver.saveAs(blob, `${fileName}.csv`);
    }

    static downloadChartImage(chartInstance: ECharts, fileName: string, format: 'png' | 'jpg') {
        let img = new Image();
        img.src = chartInstance.getDataURL({
            // @ts-ignore
            type: format,
        });

        FileSaver.saveAs(img.src, `${fileName}.${format}`);
    }
}
