import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable, of} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {
    APIResponse,
    DataTableParam,
    Filter,
    FilterFieldOptionRequest,
    FilterFieldOptionResponse,
    PagingResponse,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {
    EconomicsOverTimeResponse,
    Project,
    ProjectAssetDto,
    ProjectCostAnalysis,
    ProjectCreate,
    ProjectDto,
    ProjectUpdate,
} from '@core/interfaces/engin/program-management/project';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';
import {TableService} from '@core/utils/table.service';

@Injectable()
export class ProjectApi {
    private readonly prefix = 'projects';

    constructor(private api: HttpService) {}

    /*
     * APIs for Projects model
     */
    public createProject(req: ProjectCreate): Observable<APIResponse<Project>> {
        return this.api.post(`${this.prefix}/`, req);
    }

    public updateProject(projectId: number, req: ProjectUpdate): Observable<APIResponse<Project>> {
        return this.api.put(`${this.prefix}/${projectId}`, req);
    }

    public deleteProject(projectId: number): Observable<APIResponse<boolean>> {
        return this.api.delete(`${this.prefix}/${projectId}`);
    }

    public getProject(projectId: number): Observable<APIResponse<Project>> {
        return this.api.get(`${this.prefix}/${projectId}`);
    }

    public getProjectList(params: Filter[] = [], pageSize: number = 10): Observable<PagingResponse<Project>> {
        let paramQuery: string = TableService.createFiltersParamsString(params);
        paramQuery = TableService.addPagingParams(paramQuery, 1, pageSize);
        return this.api.get(`${this.prefix}/list?${paramQuery}`);
    }

    /*
     * APIs for ProjectDto
     */
    public getProjectDto(workflowItemId: number, projectId: number): Observable<APIResponse<ProjectDto>> {
        return this.api.get(`${this.prefix}/dto/${workflowItemId}/${projectId}`);
    }

    public getProjectDtoList(
        workflowItemId: number,
        params: Filter[] = [],
        pageSize: number = 10,
    ): Observable<PagingResponse<ProjectDto>> {
        let paramQuery: string = TableService.createFiltersParamsString(params);
        paramQuery = TableService.addPagingParams(paramQuery, 1, pageSize);
        return this.api.get(`${this.prefix}/dto/${workflowItemId}/list?${paramQuery}`);
    }

    public getProjectDtoListCsv(workflowItemId: number, params: Filter[] = []): Observable<APIResponse<DownloadURL>> {
        let paramQuery: string = TableService.createFiltersParamsString(params);
        return this.api.get(`${this.prefix}/dto/${workflowItemId}/list/csv?${paramQuery}`);
    }

    public getProjectDtoListTable(workflowItemId: number): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/dto/${workflowItemId}/list`);
    }

    /*
     * APIs for ProjectAssetDto
     */
    public getProjectAssetDtoList(
        workflowItemId: number,
        filters: Filter[] = [],
        pageSize: number = 10,
    ): Observable<PagingResponse<ProjectAssetDto>> {
        let paramQuery = TableService.addPagingParams('', 1, pageSize);
        return this.api.post(`${this.prefix}/assets/${workflowItemId}/list?${paramQuery}`, {filters});
    }

    public getProjectAssetsDtoListCsv(
        workflowItemId: number,
        filters: Filter[] = [],
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.post(`${this.prefix}/assets/${workflowItemId}/list/csv`, {filters});
    }

    public getProjectAssetDtoListTable(workflowItemId: number, filters: Filter[] = []): Observable<DataSource> {
        return this.api.postServerDataSource(`${this.prefix}/assets/${workflowItemId}/list`, {filters});
    }

    /*
     * APIs for project scope
     */
    public getAssetScopeFilters(): Observable<APIResponse<TableFilterConfig[]>> {
        return this.api.get(`${this.prefix}/scope/options/filters`);
    }

    public getAssetFilterFieldOptions(
        workflowItemId: number,
        filterOption: FilterFieldOptionRequest,
    ): Observable<APIResponse<FilterFieldOptionResponse>> {
        return this.api.post(`${this.prefix}/scope/${workflowItemId}/options/filters/field`, filterOption);
    }

    public getAssetsByFiltersListTable(workflowItemId: number, filters: Filter[]): Observable<DataSource> {
        return this.api.postServerDataSource(`${this.prefix}/scope/${workflowItemId}/assets/list`, {
            filterParams: filters,
        });
    }

    public getAssetsByFiltersListCsv(
        workflowItemId: number,
        dataTableParam: DataTableParam,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.post(`${this.prefix}/scope/${workflowItemId}/assets/list/csv`, dataTableParam);
    }

    /*
     * APIs for project-modifying actions
     */
    public removeProjectOutlierByProjectId(
        workflowItemId: number,
        projectId: number,
    ): Observable<APIResponse<boolean>> {
        return this.api.get(`${this.prefix}/action/outliers/${workflowItemId}/${projectId}`);
    }

    public generateProjectBoundaryByProjectId(
        workflowItemId: number,
        projectId: number,
    ): Observable<APIResponse<boolean>> {
        return this.api.get(`${this.prefix}/action/boundary/${workflowItemId}/${projectId}`);
    }

    /*
     * Other support APIs
     */
    public projectNameExistsValidator(projectName: string): Observable<APIResponse<boolean>> {
        return this.api.get(`${this.prefix}/name/${projectName}`);
    }

    // TODO: this is being removed and refactored into alternatives
    getEconomicsOverTime(id: number, year: number): Observable<APIResponse<EconomicsOverTimeResponse>> {
        //TODO change api
        return of({
            response: {
                data: {
                    legend: 'legend',
                    labels: ['2021', '2022', '2023', '2024', '2025'],
                    data: [21, 45, 68, 38, 56],
                    unit: {
                        prefix: '',
                        suffix: '',
                        symbol: '',
                    },
                },
                info: {
                    tco_forced: 'tco_forced',
                    tco_optimal: 'tco_optimal',
                    tco_force_delta: 'tco_force_delta',
                },
            },
            status: 200,
            message: 'success',
        });
    }
    findProjectCostAnalysis(id: number): Observable<APIResponse<ProjectCostAnalysis>> {
        //TODO change api
        return of({
            response: {
                data: {
                    legend: 'legend',
                    labels: ['Material', 'Labour', 'Vehicle & Tools', 'Other'],
                    data: [2100, 45000, 680, 3800],
                    unit: {
                        prefix: '',
                        suffix: '',
                        symbol: '',
                    },
                },
                info: {
                    capex_total: 'capex_total',
                    opex_total: 'opex_total',
                    opex_annual_average: 'opex_annual_average',
                    opex_5year_total: 'opex_5year_total',
                    opex_lifetime_total: 'opex_lifetime_total',
                },
                list: [
                    {
                        costComponent: 'Pole',
                        material: 300000,
                        labour: 1200,
                        vehicleTools: 10000,
                        other: 0,
                    },
                    {
                        costComponent: 'OH TX',
                        material: 50000,
                        labour: 172200,
                        vehicleTools: 10000,
                        other: 0,
                    },
                    {
                        costComponent: 'Pole',
                        material: 100000,
                        labour: 20000,
                        vehicleTools: 2300000,
                        other: 5000,
                    },
                ],
            },
            status: 200,
            message: 'success',
        });
    }
    downloadCostAnalysisListDtoCsv(projectId: number): Observable<APIResponse<DownloadURL>> {
        return this.api.get(`${this.prefix}/costanalysis/listDtoCsv?projectId=${projectId}`);
    }
}
