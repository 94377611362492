import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse, Filter, PagingResponse, SimpleMultiSeries} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {KpiData} from '@theme/components/chart-kpi/chart-kpi.component';
import {
    Program,
    ProgramBudgetOutcome,
    ProgramUpdateBudgetDto,
    ProgramCreateDto,
    ProgramFullDto,
    ProgramUpdateDto,
    ProgramDto,
    ProgramBudgetByOutcomeRequest,
    ProgramBudgetRequest,
    InvestmentPriorityType,
} from '@core/interfaces/engin/program-management/program';
import {TableService} from '@core/utils/table.service';
import {PmDataRequest} from '@core/interfaces/engin/program-management/pm-reporting';
import {KpiType} from '@core/interfaces/engin/program-management/segment';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';

@Injectable()
export class ProgramApi {
    private readonly prefix = 'programs';

    constructor(private api: HttpService) {}

    public createProgram(programCreate: ProgramCreateDto): Observable<APIResponse<Program>> {
        return this.api.post(`${this.prefix}`, programCreate);
    }

    public editProgramDetails(programId: number, programUpdate: ProgramUpdateDto): Observable<APIResponse<Program>> {
        return this.api.put(`${this.prefix}/${programId}`, programUpdate);
    }

    public deleteProgram(programId: number): Observable<APIResponse<boolean>> {
        return this.api.delete(`${this.prefix}/${programId}`);
    }

    public editProgramBudget(
        workflowItemId: number,
        updateDto: ProgramUpdateBudgetDto,
    ): Observable<APIResponse<ProgramFullDto>> {
        return this.api.put(`${this.prefix}/budget/${workflowItemId}`, updateDto);
    }

    public getProgramList(params: Filter[] = [], pageSize: number = 10): Observable<PagingResponse<Program>> {
        let paramQuery = TableService.createFiltersParamsString(params);
        paramQuery = TableService.addPagingParams(paramQuery, 1, pageSize);
        return this.api.get(`${this.prefix}/list?${paramQuery}`);
    }

    /*
     * APIs for ProgramDto
     */
    public getProgramDto(programId: number, request: PmDataRequest): Observable<APIResponse<ProgramDto>> {
        return this.api.post(`${this.prefix}/dto/${programId}`, request);
    }

    public getProgramDtoList(
        request: PmDataRequest,
        params: Filter[],
        pageSize: number = 10,
    ): Observable<PagingResponse<ProgramDto>> {
        let paramQuery = TableService.createFiltersParamsString(params);
        paramQuery = TableService.addPagingParams(paramQuery, 1, pageSize);
        return this.api.post(`${this.prefix}/dto/list?${paramQuery}`, request);
    }

    public getProgramDtoListCsv(request: PmDataRequest, params: Filter[] = []): Observable<APIResponse<DownloadURL>> {
        let paramQuery = TableService.createFiltersParamsString(params);
        return this.api.post(`${this.prefix}/dto/list/csv?${paramQuery}`, request);
    }

    public getProgramDtoListTable(request: PmDataRequest): Observable<DataSource> {
        return this.api.postServerDataSource(`${this.prefix}/dto/list`, request);
    }

    /*
     * APIs for ProgramFullDto
     */
    public getProgramDtoFull(programId: number): Observable<APIResponse<ProgramFullDto>> {
        return this.api.get(`${this.prefix}/dto-full/${programId}`);
    }

    /*
     * APIs related to analysis of results and budgets
     */
    public findProgramKpis(programId: number, req: PmDataRequest): Observable<APIResponse<KpiData[]>> {
        return this.api.post(`${this.prefix}/analysis/${programId}/kpi`, req);
    }

    public getBudgetChart(req: ProgramBudgetRequest): Observable<APIResponse<SimpleMultiSeries<number>>> {
        return this.api.post(`${this.prefix}/analysis/budget/chart`, req);
    }

    public getProgramBudgetByOutcomes(
        req: ProgramBudgetByOutcomeRequest,
    ): Observable<APIResponse<ProgramBudgetOutcome[]>> {
        return this.api.post(`${this.prefix}/analysis/budget/list-by-outcomes`, req);
    }

    /*
     * Other support APIs
     */
    public getInvestmentPriorityOptions(): Observable<APIResponse<InvestmentPriorityType[]>> {
        return this.api.get(`${this.prefix}/options/priority`);
    }

    public getKpiOptions(): Observable<APIResponse<KpiType[]>> {
        return this.api.get(`${this.prefix}/options/kpis`);
    }

    public validateProgramName(programName: string): Observable<APIResponse<boolean>> {
        return this.api.get(`${this.prefix}/name/${programName}`);
    }

    // TODO: move to project with filter by segmentId
    downloadProgramAssets(programId: number, workflowItemId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.get(`${this.prefix}/download/assets/${programId}/study/${workflowItemId}`);
    }
}
