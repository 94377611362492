import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {QUICK_FILTER_LIST, QuickFilterCategory} from '../../../model/filter';
import {AnalyzerPopoutFilter, GeospatialFilterSettings} from '@core/interfaces/common/popout';
import {debounceTime, filter, map, shareReplay, startWith, takeUntil, tap} from 'rxjs/operators';
import {Filter, FilterFieldType} from '@core/interfaces/system/system-common';
import {StudiesStore} from '@store/common/studies.store';
import {GeospatialFilterStore} from '../../../api/geospatial-filter.store';

@Injectable()
export class FilterControlsStore extends Unsubscribable {
    public selectedOption = new BehaviorSubject(null);
    public selectedLegend = new BehaviorSubject<any>(null);

    private changeSubscription: Subscription;
    private searchSubscription: Subscription;

    public formGroup = this.fb.group({});
    private formGroupValue = new BehaviorSubject(null);

    public filtersFormGroupChanged$ = new BehaviorSubject<boolean>(false);

    private filterSettings = new BehaviorSubject<GeospatialFilterSettings>(null);
    readonly filterSettings$: Observable<GeospatialFilterSettings> = this.filterSettings.asObservable().pipe(
        filter((settings) => settings != null),
        tap((settings) => {
            this.changeSubscription && this.changeSubscription.unsubscribe();
            this.searchSubscription && this.searchSubscription.unsubscribe();
            this.initGeospatialFilters(settings);
        }),
        shareReplay(1),
    );

    readonly currentFilters$: Observable<Filter[]> = this.formGroupValue.asObservable().pipe(
        filter((value) => value !== null),
        map((value) => this.mapFormGroupValue(value)),
    );

    get currentFilters(): Filter[] {
        const _formGroupValue = this.formGroupValue.value;
        if (_formGroupValue) return this.mapFormGroupValue(_formGroupValue);
        return [];
    }

    // Control panel > Filter > quick filters; map control > quick filters
    private quickFilters: BehaviorSubject<QuickFilterCategory[]> = new BehaviorSubject<QuickFilterCategory[]>(
        QUICK_FILTER_LIST,
    );
    readonly quickFilters$: Observable<QuickFilterCategory[]> = this.quickFilters.asObservable();

    constructor(
        private fb: FormBuilder,
        private geospatialFilterStore: GeospatialFilterStore,
        protected studiesStore: StudiesStore,
    ) {
        super();

        this.geospatialFilterStore.geospatialFilterSettings$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((settings) => this.updateFilterSettings(settings));
    }

    get filtersFormGroupControls(): any {
        return this.filtersFormGroup?.controls;
    }

    get filtersFormGroup(): FormGroup {
        return this.formGroup.get('filters') as FormGroup;
    }

    public resetGeospatialFilters() {
        if (this.filterSettings && this.filterSettings.value) {
            const currentFilters = this.getActiveFilterSettings();
            const resetFilters = {
                ...currentFilters,
                filters: currentFilters.filters?.map((group) => {
                    if (group.fieldType === FilterFieldType.STRING) {
                        return {
                            ...group,
                            options: group.options?.map((option) => {
                                return {
                                    ...option,
                                    selected: false,
                                };
                            }),
                        };
                    } else if (FilterFieldType.isNumericFieldType(group.fieldType)) {
                        return {...group};
                    }
                }),
            };

            this.destroy();
            this.initGeospatialFilters(resetFilters);
        }
    }

    get searchStringFormControl(): FormControl {
        return this.formGroup.get('searchString') as FormControl;
    }

    public destroy() {
        this.changeSubscription && this.changeSubscription.unsubscribe();
        this.searchSubscription && this.searchSubscription.unsubscribe();
        this.filtersFormGroup?.reset();
        this.searchStringFormControl?.reset();
    }

    public getActiveFilterSettings() {
        return this.filterSettings.value;
    }

    public updateFilterSettings(settings: GeospatialFilterSettings, settingName?: string) {
        let resultSettings: GeospatialFilterSettings = JSON.parse(JSON.stringify(settings));
        if (settingName) {
            resultSettings = {
                ...this.filterSettings.value,
                [settingName]: settings[settingName].map((item) => {
                    return {
                        ...item,
                    };
                }),
            };
        }
        this.filterSettings.next(resultSettings);
    }

    private isEquivalent(val1: any, val2: any): boolean {
        if (val1 instanceof Object && val2 instanceof Object) {
            const val1props = Object.keys(val1);
            const val2props = Object.keys(val2);

            if (val1props.length !== val2props.length) return false;

            for (let i = 0; i < val1props.length; i++) {
                if (val1props[i] === 'enabled') continue;
                const val2Prop = val2props.find((item) => item === val1props[i]);
                if (!val2Prop) return false;

                if (!this.isEquivalent(val1[val2Prop], val2[val2Prop])) return false;
            }
            return true;
        }
        return val1 === val2;
    }

    private setFiltersFormGroup(group: FormGroup) {
        if (this.filtersFormGroup) {
            this.filtersFormGroup.reset(group.value);
            Object.keys(group.value).forEach((prop) => {
                this.filtersFormGroupControls[prop].enable();
            });
        } else {
            this.formGroup.setControl('filters', group);
            this.filtersFormGroupChanged$.next(true);
        }
    }

    private setSearchStringFormControl(search: FormControl) {
        if (this.searchStringFormControl) {
            this.searchStringFormControl.reset(search.value);
        } else {
            this.formGroup.setControl('searchString', search);
        }
    }

    private updateTotals() {
        this.getActiveFilterSettings().filters.forEach((filter) => {
            if (this.filtersFormGroupControls[filter.fieldKey].value && filter?.fieldType === FilterFieldType.STRING) {
                const control = this.filtersFormGroupControls[`${filter.fieldKey}_selected`];
                const selected = this.filtersFormGroupControls[filter.fieldKey].value.filter(
                    (item) => item.selected,
                ).length;
                const all = this.filtersFormGroupControls[filter.fieldKey].value.length;
                control.setValue(selected === all ? 'All' : selected, {emitEvent: false});
            }
        });
    }

    private search(searchString: string) {
        const filters = {};
        this.getActiveFilterSettings().filters.forEach((filter) => {
            if (this.filtersFormGroupControls[filter.fieldKey].value && filter.fieldType === FilterFieldType.STRING) {
                filters[filter.fieldKey] = this.filtersFormGroupControls[filter.fieldKey].value.map((item) => {
                    return {
                        ...item,
                        enabled: `${item.key}${item.name}`.toLowerCase().includes(searchString.toLowerCase()),
                    };
                });
            }
        });

        this.filtersFormGroup.patchValue(filters, {
            emitEvent: false,
            onlySelf: true,
        });
    }

    private subscribeOnChanges() {
        this.changeSubscription = this.filtersFormGroup.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                startWith(this.filtersFormGroup.value),
                tap(() => {
                    this.updateTotals();
                }),
                tap((value) => {
                    if (this.formGroupValue.value === null) {
                        this.formGroupValue.next(value);
                    }
                }),
                filter((value) => {
                    return !this.isEquivalent(this.formGroupValue.value, value);
                }),
                debounceTime(500),
            )
            .subscribe((value) => {
                this.updateFilterSettingsValue(value);
                this.formGroupValue.next(value);
            });

        this.searchSubscription = this.searchStringFormControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((searchString) => this.search(searchString));
    }

    private updateFilterSettingsValue(next: any) {
        // Update filters
        this.filterSettings.value.filters.forEach((item: AnalyzerPopoutFilter) => {
            if (next[item.fieldKey] !== null && next[item.fieldKey] !== undefined) {
                item.options = next[item.fieldKey];
            }
        });
    }

    private mapFormGroupValue(popoutValue: any): Filter[] {
        // Filters
        const filterList: Filter[] = [];

        this.filterSettings.value.filters.forEach((item: AnalyzerPopoutFilter) => {
            let options = [];

            if (item.fieldType === FilterFieldType.STRING) {
                popoutValue[item.fieldKey]?.forEach((option) => {
                    if (option.selected) options.push(option.key);
                });

                // Do not add filters if there are no selected values
                if (options.length > 0) {
                    filterList.push({
                        fieldKey: item.fieldKey,
                        fieldType: item.fieldType,
                        operator: item.operator,
                        values: options,
                    });
                }
            } else if (item.fieldType === FilterFieldType.DATE_RANGE) {
                let _value = popoutValue.filters[item.fieldKey],
                    min = _value?.start,
                    max = _value?.end;

                if (min || max) {
                    options = [min ?? item.min, max ?? item.max];

                    filterList.push({
                        fieldKey: item.fieldKey,
                        fieldType: item.fieldType,
                        operator: item.operator,
                        values: options,
                    });
                }
            } else if (FilterFieldType.isNumericFieldType(item.fieldType)) {
                let _value = popoutValue[item.fieldKey],
                    min = _value?.min,
                    max = _value?.max;

                if (min || max) {
                    options = [min ?? item.min, max ?? item.max];

                    filterList.push({
                        fieldKey: item.fieldKey,
                        fieldType: item.fieldType,
                        operator: item.operator,
                        values: options,
                    });
                }
            }
        });

        return filterList;
    }

    private initGeospatialFilters(settings: GeospatialFilterSettings): void {
        this.initFilters(settings.filters);
        this.setSearchStringFormControl(this.fb.control(''));
        this.subscribeOnChanges();
    }

    private initFilters(initialFilters: AnalyzerPopoutFilter[]): void {
        const filters = this.fb.group({});
        initialFilters.forEach((item) => {
            if (item.fieldType === FilterFieldType.STRING) {
                filters.setControl(
                    `${item.fieldKey}_selected`,
                    this.fb.control({
                        value: 0,
                        disabled: true,
                    }),
                );
                filters.setControl(item.fieldKey, this.fb.control(item.options));
            } else if (FilterFieldType.isNumericFieldType(item.fieldType)) {
                filters.setControl(item.fieldKey, this.fb.control({}));
            }
        });
        this.setFiltersFormGroup(filters);
    }

    public resetQuickFilters(filters?: QuickFilterCategory[]) {
        this.quickFilters.next(filters || QUICK_FILTER_LIST);
    }

    public getQuickFilters(): QuickFilterCategory[] {
        return this.quickFilters.value;
    }

    /*
     * Support for quick filtering
     */

    // Update geospatial filters based on quick filter selections
    private propgateQuickFilters(quickFilters: QuickFilterCategory[]) {
        const currentSettings = this.getActiveFilterSettings();
        // Quick filters will turn certain asset class combinations on/off
        const newFilterSettings = {
            ...currentSettings,
            filters: currentSettings.filters.map((group) => {
                if (group.fieldKey.toLowerCase() === 'assetclass') {
                    return this.applyQuickFilters('class', group, quickFilters);
                } else if (group.fieldKey.toLowerCase() === 'assetclasscode') {
                    return this.applyQuickFilters('code', group, quickFilters);
                } else {
                    return {
                        ...group,
                    };
                }
            }),
        };

        this.updateFilterSettings(newFilterSettings);
    }

    // Toggle quick filter option
    public quickFilterOptionToggle(group: string, option: string) {
        if (group && option) {
            const newQuickFilters = this.quickFilters.value.map((g) => {
                return {
                    ...g,
                    options: g.options.map((o) => {
                        return {
                            ...o,
                            selected: g.code === group && o.code === option ? !o.selected : o.selected,
                        };
                    }),
                };
            });
            this.quickFilters.next(newQuickFilters);
            // Quick filters simply pre-selects certain general filter options
            this.propgateQuickFilters(newQuickFilters);
        }
    }

    private applyQuickFilters(type: string, group: any, quickFilters: QuickFilterCategory[]) {
        const lineEnabled: boolean = this.checkQuickFilterEnabled(quickFilters, 'shape', 'line');
        const pointEnabled: boolean = this.checkQuickFilterEnabled(quickFilters, 'shape', 'point');
        const ohEnabled: boolean = this.checkQuickFilterEnabled(quickFilters, 'orientation', 'overhead');
        const ugEnabled: boolean = this.checkQuickFilterEnabled(quickFilters, 'orientation', 'underground');
        return {
            ...group,
            options: group.options.map((option) => {
                return {
                    ...option,
                    selected:
                        type === 'class'
                            ? this.setSelectedByClass(option.key, lineEnabled, pointEnabled, ohEnabled, ugEnabled)
                            : this.setSelectedByClassCode(option.key, lineEnabled, pointEnabled, ohEnabled, ugEnabled),
                };
            }),
        };
    }

    /*
     * Quick filter is enabled if:
     * a) Option is selected
     * b) All options in this group are unselected (i.e. no filtering is applied)
     */
    private checkQuickFilterEnabled(quickFilters: QuickFilterCategory[], group: string, option: string): boolean {
        const findGroup = quickFilters.filter((g) => g.code.toLowerCase() === group.toLowerCase());
        if (findGroup.length > 0 && findGroup[0] != null) {
            const noOptionsSelected: boolean =
                findGroup[0].options.filter((o) => !o.selected).length === findGroup[0].options.length;
            const findOption = findGroup[0].options.filter((o) => o.code.toLowerCase() === option.toLowerCase());
            if (findOption.length > 0 && findOption[0] != null) {
                return findOption[0].selected ? findOption[0].selected : noOptionsSelected;
            }
        }
        return false;
    }

    private setSelectedByClassCode(
        code: string,
        lineEnabled: boolean,
        pointEnabled: boolean,
        ohEnabled: boolean,
        ugEnabled: boolean,
    ): boolean {
        const c = code.toLowerCase();
        // All underground classes include "ug_" in class code
        if (c.includes('ug_')) {
            // Check linear codes
            if (c.includes('cable')) {
                return ugEnabled && lineEnabled;
            }
            // Else assume point asset
            return ugEnabled && pointEnabled;
        }

        // Else code is overhead
        if (c.includes('cond')) {
            return ohEnabled && lineEnabled;
        }
        // Else assume point asset
        return ohEnabled && pointEnabled;
    }

    private setSelectedByClass(
        className: string,
        lineEnabled: boolean,
        pointEnabled: boolean,
        ohEnabled: boolean,
        ugEnabled: boolean,
    ): boolean {
        const c = className.toLowerCase();
        // All underground classes include "ug_" in class code
        if (c.includes('underground') || c.includes('u/g')) {
            // Check linear codes
            if (c.includes('cable')) {
                return ugEnabled && lineEnabled;
            }
            // Else assume point asset
            return ugEnabled && pointEnabled;
        }

        // Else code is overhead
        if (c.includes('cond') || c.includes('conductor')) {
            return ohEnabled && lineEnabled;
        }
        // Else assume point asset
        return ohEnabled && pointEnabled;
    }
}
