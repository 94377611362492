import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Layer, MetricLayer, ProjectLayer, SystemLayer} from './layer';
import {debounceTime, distinctUntilChanged, filter, finalize, skip, take, takeUntil} from 'rxjs/operators';
import {GeospatialViewerService} from '../api/geospatial-viewer.service';
import {GeospatialRequest} from '../model/api';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {GeospatialViewerControlsStore} from '../api/geospatial-viewer-controls.store';
import {GeospatialResponse} from '../../geospatial-viewer-old/model/api';
import {FormBuilder} from '@angular/forms';
import {GeospatialFilterStore} from '../api/geospatial-filter.store';
import {StudiesStore} from '@store/common/studies.store';

@Injectable()
export class LayersService extends Unsubscribable {
    // Separate Observables for each type of layer
    private systemLayers: BehaviorSubject<SystemLayer[]> = new BehaviorSubject<SystemLayer[]>([new SystemLayer()]);
    systemLayers$: Observable<SystemLayer[]> = this.systemLayers.asObservable();

    private projectLayers: BehaviorSubject<ProjectLayer[]> = new BehaviorSubject<ProjectLayer[]>([new ProjectLayer()]);
    projectLayers$: Observable<ProjectLayer[]> = this.projectLayers.asObservable();

    private metricLayers: BehaviorSubject<MetricLayer[]> = new BehaviorSubject<MetricLayer[]>([
        this.createMetricLayer(),
    ]);
    metricLayers$: Observable<MetricLayer[]> = this.metricLayers.asObservable();

    // New subject that will emit changes for the specific layer that needs to be re-rendered
    private _metricLayerRerender: BehaviorSubject<Layer | null> = new BehaviorSubject<Layer | null>(null);
    metricLayerRerender$: Observable<Layer | null> = this._metricLayerRerender.asObservable();

    constructor(
        private geospatialViewerService: GeospatialViewerService,
        private geospatialViewerControlsStore: GeospatialViewerControlsStore,
        private fb: FormBuilder,
        private geospatialFilterStore: GeospatialFilterStore,
        protected studiesStore: StudiesStore,
    ) {
        super();

        this.geospatialViewerControlsStore.config$
            .pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged(),
                filter((r) => !!r),
                skip(1),
                debounceTime(500),
            )
            .subscribe(() => {
                this.metricLayers.value.forEach((layer: MetricLayer) => this.refreshMetricLayerData(layer));
            });

        this.geospatialViewerControlsStore.currentStudyId$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.resetToDefaultMetricLayer();
        });
    }

    createMetricLayer(): MetricLayer {
        return new MetricLayer(this.fb, this.geospatialFilterStore, this.studiesStore);
    }

    addMetricLayer(layer: MetricLayer) {
        const currentLayers = this.metricLayers.getValue();
        this.closeLayers(currentLayers);
        this.metricLayers.next([...currentLayers, layer]);
    }

    duplicateMetricLayer(layer: MetricLayer): MetricLayer {
        const newLayer = this.createMetricLayer();

        // Copy properties from the existing layer to the new one
        newLayer.mapDataDetails = {...layer.mapDataDetails};
        newLayer.visible = layer.visible;
        newLayer.name = layer.name;
        newLayer.mapData = [...layer.mapData];
        newLayer.metrics = {...layer.metrics};

        // Clone the form values (optional, depending on use case)
        newLayer.layerForm.patchValue(layer.layerForm.value);

        //set these values in some time to make sure:
        // 1. metric and categoriesSelected are not reset after measure changes
        // 2. "filters" control already exists in the layerForm
        setTimeout(() => {
            newLayer.filterControlsStore.resetQuickFilters(layer.filterControlsStore.getQuickFilters());

            newLayer.layerForm.patchValue({
                metric: layer.layerForm.value.metric,
                categoriesSelected: layer.layerForm.value.categoriesSelected,
                filters: layer.layerForm.value.filters,
            });
        }, 300);

        // Add the duplicated layer to the list
        this.addMetricLayer(newLayer);

        // Return the new layer instance
        return newLayer;
    }

    closeLayers(currentLayers: Layer[]) {
        currentLayers.forEach((layer) => layer.closeLayer());
    }

    removeMetricLayer(layer: MetricLayer) {
        layer.visible = false;
        this.rerenderMetricLayer(layer);

        const currentLayers = this.metricLayers.getValue().filter((_layer) => _layer.id !== layer.id);
        this.metricLayers.next(currentLayers);
    }

    resetToDefaultMetricLayer() {
        const layerCurrentMetric = this.metricLayers.getValue();

        layerCurrentMetric.forEach((layer: MetricLayer) => {
            layer.visible = false;
            this.rerenderMetricLayer(layer);
        });

        this.metricLayers.next([this.createMetricLayer()]);
    }

    toggleLayerVisibility(layer: Layer) {
        if (layer) {
            layer.toggleVisibility();

            // Trigger re-rendering of the map
            if (layer instanceof MetricLayer) {
                layer.visible ? this.refreshMetricLayerData(layer) : this.rerenderMetricLayer(layer);

                this.metricLayers.next(this.metricLayers.getValue());
            }
        }
    }

    public refreshMetricLayerData(layer: MetricLayer) {
        if (layer.layerForm.valid && layer.layerForm.value.visible) {
            let req: GeospatialRequest = {
                visualizationType: layer.layerForm.value.visualizationType.value,
                measure: layer.layerForm.value.measure.code,
                metric: layer.layerForm.value.metric.code,
                valueType: layer.layerForm.value.metric.valueType,
                filterList: layer.getFilters(),
                valueFilter: layer.getCategories(),
                studyId: this.geospatialViewerControlsStore.getCurrentStudyId(),
                centerConfig: this.geospatialViewerControlsStore.getConfig()?.centerCoordinate,
            };

            layer.setLoading(true);

            this.geospatialViewerService
                .getMapGeoData(req.studyId, req)
                .pipe(
                    finalize(() => {
                        layer.setLoading(false);
                    }),
                )
                .subscribe((mapDataSource: GeospatialResponse) => {
                    layer.updateMapDataSource(mapDataSource);
                    layer.setDataDetails(mapDataSource.dataDetails);

                    this.rerenderMetricLayer(layer); // Re-render the map

                    this.metricLayers.next(this.metricLayers.getValue());
                });
        }
    }

    // Method to emit updates for a specific Metrics layer
    private rerenderMetricLayer(layer: Layer) {
        this._metricLayerRerender.next(layer); // Emit the specific layer that was updated
    }
}
