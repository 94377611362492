import {VisualizationType} from '../../../../pages/geospatial-viewer/model/visualization';
import {DataDetails, SpatialEntityData} from '../../../../pages/geospatial-viewer/model/api';
import {GeospatialCenterCoordinate} from '../../../../pages/geospatial-viewer/model/viewer-config';
import {Measure, Metric, MetricType} from '../../../../pages/geospatial-viewer/model/metric';

export class SpatialCardResponseAlt {
    configData: GeospatialViewerConfigurationAlt;
    sourceData: GeospatialResponseAlt;
}

export class GeospatialViewerConfigurationAlt {
    measures: Array<Measure>;
    centerCoordinate: GeospatialCenterCoordinate;
}

export class GeospatialResponseAlt {
    visualizationType: VisualizationType;
    dataDetails: DataDetails;
    metricType: MetricType;
    data: SpatialEntityData[];
}
