import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {catchError, debounceTime, filter, map, mergeMap, shareReplay, startWith, takeUntil, tap} from 'rxjs/operators';
import {
    ControlPanelSettings,
    DashboardCardBody,
    DisplaySettings,
    Page,
    PageGroup,
    PagesService,
} from '@core/interfaces/common/pages';
import {NavigationEnd, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NbAclService} from '@nebular/security';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {StudiesStore} from '@store/common/studies.store';
import {UsersStore} from '@store/common/users.store';
import {User, WorkflowInfo} from '@core/interfaces/common/users';
import {switchMap} from 'rxjs/internal/operators/switchMap';

@Injectable()
export class PagesStore extends Unsubscribable implements OnDestroy {
    private pageGroups: BehaviorSubject<PageGroup[]> = new BehaviorSubject<PageGroup[]>(null);
    readonly pageGroups$ = this.pageGroups.asObservable().pipe(takeUntil(this.unsubscribe$), shareReplay(1));

    private currentUrl = new BehaviorSubject<string>('');
    readonly currentUrl$ = this.currentUrl.asObservable().pipe(
        filter((url) => !!url),
        shareReplay(1),
    );

    private currentUrlWithPageGroups$ = combineLatest<Observable<string>, Observable<PageGroup[]>>([
        this.currentUrl$,
        this.pageGroups$.pipe(filter((pageGroups) => !!pageGroups && !!this.studiesStore.getActiveWorkflows())),
    ]).pipe(shareReplay(1));

    /*
     * Four level hierarchy of navigation:
     * 1. Group (modules, tools & add-ons, archive, configuration)
     * 2. Feature (Analyzer, Study History)
     * 3. Page (EOL Metrics, Investment Plan)
     * 4. Tab (Economic (in EOL Metrics, Demographics), Health (in Asset Details))
     */
    readonly activeGroup$: Observable<PageGroup> = this.currentUrlWithPageGroups$.pipe(
        map(([url, pagesGroups]: [string, PageGroup[]]) => {
            try {
                return []
                    .concat(...pagesGroups)
                    .find((group) =>
                        group.children.map((page) => (url.indexOf(page.link) >= 0 ? 1 : 0)).reduce((a, b) => a + b, 0),
                    );
            } catch (err) {
                return null;
            }
        }),
    );

    readonly activeFeature$: Observable<Page> = this.currentUrlWithPageGroups$.pipe(
        map(([url, pagesGroups]: [string, PageGroup[]]) => {
            try {
                return []
                    .concat(...pagesGroups.map((group) => group.children))
                    .find((page) => url.indexOf(page.link) >= 0);
            } catch (err) {
                return null;
            }
        }),
    );
    readonly activePage$: Observable<Page> = this.currentUrlWithPageGroups$.pipe(
        map(([url, pagesGroups]: [string, PageGroup[]]) => {
            try {
                return []
                    .concat(...pagesGroups.map((group) => group.children))
                    .find((page) => url.indexOf(page.link) >= 0)
                    .children.find((page) => url.indexOf(page.link) >= 0);
            } catch (err) {
                return null;
            }
        }),
    );
    readonly activeTab$: Observable<Page> = this.currentUrlWithPageGroups$.pipe(
        map(([url, pagesGroups]: [string, PageGroup[]]) => {
            try {
                return []
                    .concat(
                        ...[]
                            .concat(...pagesGroups.map((group) => group.children))
                            .find((page) => url.indexOf(page.link) >= 0)
                            .children.map((tab) => tab.children || []),
                    )
                    .find((tab) => url.indexOf(tab.link) >= 0);
            } catch (err) {
                return null;
            }
        }),
    );

    readonly activeView$: Observable<Page> = this.currentUrlWithPageGroups$.pipe(
        map(([url, pagesGroups]: [string, PageGroup[]]) => {
            try {
                return []
                    .concat(
                        ...[]
                            .concat(...pagesGroups.map((group) => group.children))
                            .find((page) => url.indexOf(page.link) >= 0)
                            .children.map((tab) => tab.children || []),
                    )
                    .find((tab) => url.indexOf(tab.link) >= 0)
                    .children.find((view) => url.indexOf(view.link) >= 0);
            } catch (err) {
                return null;
            }
        }),
    );

    private routerEventsSubscription: Subscription;

    constructor(
        private pagesService: PagesService,
        private router: Router,
        private aclService: NbAclService,
        private fb: FormBuilder,
        private studiesStore: StudiesStore,
        private usersStore: UsersStore,
    ) {
        super();

        this.routerEventsSubscription = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event) => (event as NavigationEnd).urlAfterRedirects),
                shareReplay(1),
            )
            .subscribe((url) => {
                this.currentUrl.next(url);
                this.loadPagesSettings();
            });

        combineLatest<Observable<User>, Observable<WorkflowInfo[]>>([
            this.usersStore.currentUser$,
            this.studiesStore.activeWorkflows$,
        ])
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(([currentUser, activeWorkflows]) => !!currentUser),
                startWith([]),
                switchMap(([currentUser, activeWorkflows]) => {
                    // Note: do not filter for studyId nonNull, since this blocks loading empty page settings
                    return this.refreshPages(activeWorkflows || []);
                }),
            )
            .subscribe();
    }

    public refreshPages(activeWorkflows: WorkflowInfo[]) {
        return this.pagesService.getPages().pipe(
            takeUntil(this.unsubscribe$),
            map((groups) => {
                // Update permissions
                const pagesWithPermissions: Page[] = [];
                groups.forEach((group) => {
                    this.getPagesWithPermissions(group, pagesWithPermissions);
                });

                this.aclService.allow(
                    'user',
                    'view',
                    pagesWithPermissions.filter((page) => page.roleLevel === 'user').map((page) => page.link),
                );
                this.aclService.allow(
                    'admin',
                    'view',
                    pagesWithPermissions.filter((page) => page.roleLevel === 'admin').map((page) => page.link),
                );
                this.aclService.allow(
                    'superadmin',
                    'view',
                    pagesWithPermissions.filter((page) => page.roleLevel === 'superadmin').map((page) => page.link),
                );

                // Sort page groups
                const sortedGroups = groups.sort((a, b) => {
                    return a.order - b.order;
                });

                // Update immediately to render empty page settings while waiting for studyId
                if (activeWorkflows === []) this.pageGroups.next(sortedGroups);
                return sortedGroups;
            }),
            mergeMap((pageGroups) =>
                this.pagesService.getDashboardCardBodyData(activeWorkflows).pipe(
                    map((cards: DashboardCardBody[]) => {
                        let _pageGroups: any[] = pageGroups;
                        cards.forEach((card) => {
                            _pageGroups.forEach((group, groupIndex) =>
                                group.children.forEach((child, i) => {
                                    if (child.id === card.id)
                                        _pageGroups[groupIndex].children[i] = {...child, body: card};
                                }),
                            );
                        });

                        this.pageGroups.next(_pageGroups);
                    }),
                    catchError((e) => e),
                ),
            ),
        );
    }

    resetPages() {
        this.pageGroups.next(null);
    }

    ngOnDestroy(): void {
        this.routerEventsSubscription.unsubscribe();
    }

    public pagesFormGroup = this.fb.group({});
    private pagesChangeSubscription: Subscription;

    private pagesFormGroupValue = new BehaviorSubject(null);
    readonly currentDisplay$: Observable<DisplaySettings> = combineLatest<
        Observable<PageGroup>,
        Observable<Page>,
        Observable<Page>,
        Observable<Page>,
        Observable<Page>,
        Observable<any>
    >([
        this.activeGroup$,
        this.activeFeature$,
        this.activePage$,
        this.activeTab$,
        this.activeView$,
        this.pagesFormGroupValue.asObservable(),
    ]).pipe(
        map(([group, feature, page, tab, view, graphs]) => {
            return this.mapPagesFormGroupValue(group, feature, page, tab, view, graphs);
        }),
        debounceTime(500),
    );

    readonly storedCurrentDisplay$ = this.currentDisplay$.pipe(shareReplay(1));

    private pagesSettings = new BehaviorSubject<DisplaySettings>(null);
    readonly pagesSettings$: Observable<DisplaySettings> = this.pagesSettings.asObservable().pipe(
        filter((settings) => settings != null),
        tap((settings) => {
            this.pagesChangeSubscription && this.pagesChangeSubscription.unsubscribe();
            this.initPagesSettings(settings);
        }),
        shareReplay(1),
    );

    public loadPagesSettings() {
        combineLatest<Observable<PageGroup>, Observable<Page>, Observable<Page>, Observable<Page>, Observable<Page>>([
            this.activeGroup$,
            this.activeFeature$,
            this.activePage$,
            this.activeTab$,
            this.activeView$,
        ])
            .pipe(
                map(([group, feature, page, tab, view]) => {
                    return {
                        group: group,
                        feature: feature,
                        page: page,
                        tab: tab,
                        view: view,
                    };
                }),
                filter((value) => {
                    return !this.isEquivalent(this.pagesFormGroupValue.value, value);
                }),
            )
            .subscribe((value) => {
                try {
                    let graphList = null;
                    if (value.view) {
                        graphList = value.view.graphList;
                    } else if (value.tab) {
                        graphList = value.tab.graphList;
                    } else if (value.page) {
                        graphList = value.page.graphList;
                    }
                    const settings = {
                        id:
                            (value.feature ? value.feature.id : '') +
                            (value.page ? value.page.id : '') +
                            (value.tab ? value.tab.id : '') +
                            (value.view ? value.view.id : ''),
                        groupId: value.group ? value.group.id : '',
                        featureId: value.feature ? value.feature.id : '',
                        pageId: value.page ? value.page.id : '',
                        tabId: value.tab ? value.tab.id : '',
                        viewId: value.view ? value.view.id : '',
                        graphList: graphList,
                    };
                    this.updateSettings(settings);
                } catch (err) {}
            });
    }

    public updateSettings(settings: DisplaySettings, settingName?: string) {
        let resultSettings: DisplaySettings = settings;
        if (settingName) {
            resultSettings = {
                ...this.pagesSettings.value,
                [settingName]: settings[settingName].map((item) => {
                    return {
                        ...item,
                        enabled: item.selected,
                    };
                }),
            };
        }
        this.pagesSettings.next(resultSettings);
    }

    // reset units??

    public setGraphsFormGroup(graphs: FormGroup) {
        if (this.graphsFormGroup) {
            // TODO: Remove and reset the pages form group: may want to save in the future?
            this.pagesFormGroup.removeControl('graphs');
            this.pagesFormGroup.setControl('graphs', graphs);
        } else {
            this.pagesFormGroup.setControl('graphs', graphs);
        }
    }

    get graphsFormGroup(): FormGroup {
        return this.pagesFormGroup.get('graphs') as FormGroup;
    }

    private subscribeOnChanges() {
        this.pagesChangeSubscription = this.graphsFormGroup.valueChanges
            .pipe(startWith({...this.graphsFormGroup.value}))
            .pipe(
                map((graphs) => {
                    return graphs;
                }),
                tap((value) => {
                    if (this.pagesFormGroupValue.value === null) {
                        this.pagesFormGroupValue.next(value);
                    }
                }),
                filter((value) => {
                    return !this.isEquivalent(this.pagesFormGroupValue.value, value);
                }),
            )
            .subscribe((value) => {
                this.pagesFormGroupValue.next(value);
            });
    }

    private isEquivalent(val1: any, val2: any): boolean {
        if (val1 instanceof Object && val2 instanceof Object) {
            const val1props = Object.keys(val1);
            const val2props = Object.keys(val2);

            if (val1props.length !== val2props.length) return false;

            for (let i = 0; i < val1props.length; i++) {
                if (val1props[i] === 'enabled') continue;
                const val2Prop = val2props.find((item) => item === val1props[i]);
                if (!val2Prop) return false;

                if (!this.isEquivalent(val1[val2Prop], val2[val2Prop])) return false;
            }
            return true;
        }
        return val1 === val2;
    }

    private mapPagesFormGroupValue(
        group: PageGroup,
        feature: Page,
        page: Page,
        tab: Page,
        view: Page,
        graphs: any,
    ): DisplaySettings {
        // Graphs
        const graphList = [];
        if (graphs) {
            const iter = Object.keys(graphs);
            iter.forEach((item) => {
                graphList.push({
                    graphId: graphs[item].graphId,
                    unit: graphs[item].unit,
                });
            });
        }
        // For all custom settings, default setting state is always "false".
        // Then work up the hierarchy tab < page < feature < group to find the nearest value.
        let dataAuditWidgetHidden = false;
        if (null != view) {
            dataAuditWidgetHidden =
                view.dataAuditWidgetHidden ||
                tab.dataAuditWidgetHidden ||
                page.dataAuditWidgetHidden ||
                feature.dataAuditWidgetHidden;
        } else if (null != tab) {
            dataAuditWidgetHidden =
                tab.dataAuditWidgetHidden || page.dataAuditWidgetHidden || feature.dataAuditWidgetHidden;
        } else if (null != page) {
            dataAuditWidgetHidden = page.dataAuditWidgetHidden || feature.dataAuditWidgetHidden;
        } else if (null != feature) {
            dataAuditWidgetHidden = feature.dataAuditWidgetHidden;
        }

        let controlPanel: ControlPanelSettings = null;
        if (null != view) {
            controlPanel = view.controlPanel || tab.controlPanel || page.controlPanel || feature.controlPanel;
        } else if (null != tab) {
            controlPanel = tab.controlPanel || page.controlPanel || feature.controlPanel;
        } else if (null != page) {
            controlPanel = page.controlPanel || feature.controlPanel;
        } else if (null != feature) {
            controlPanel = feature.controlPanel;
        }

        return {
            id: (feature ? feature.id : '') + (page ? page.id : '') + (tab ? tab.id : '') + (view ? view.id : ''),
            groupId: group ? group.id : '',
            featureId: feature ? feature.id : '',
            pageId: page ? page.id : '',
            tabId: tab ? tab.id : '',
            viewId: view ? view.id : '',
            graphList: graphList,
            dataAuditWidgetHidden: dataAuditWidgetHidden,
            controlPanel: controlPanel,
        };
    }

    private initPagesSettings(settings: DisplaySettings) {
        this.initPages(settings.graphList);
        this.subscribeOnChanges();
    }

    private initPages(initialGraphs: any[]) {
        const graphs = this.fb.group({});

        if (initialGraphs !== null && initialGraphs !== undefined) {
            initialGraphs.forEach((item) => {
                graphs.setControl(item.graphId, this.fb.control(item));
            });
        }
        this.setGraphsFormGroup(graphs);
    }

    private getPagesWithPermissions(page: PageGroup | Page, result: any[]) {
        if (page.children) {
            for (let i = 0; i < page.children.length; i++) {
                this.getPagesWithPermissions(page.children[i], result);
            }
        }
        result.push(page);
    }

    public getRedirect(id: string): Observable<string> | string {
        return this.pageGroups$.pipe(
            filter((pageGroups) => !!pageGroups),
            map((pageGroups: Page[]) => {
                pageGroups.forEach((group) => {
                    if (group.id === id) {
                        return group.redirectTo;
                    } else {
                        if (group.children) {
                            group.children.forEach((page) => {
                                if (page.id === id) {
                                    return page.redirectTo;
                                }
                            });
                        }
                        return '';
                    }
                });
                return '';
            }),
        );
    }

    // Get Optimizer popout configuration once
    public optimizerControlPanelConfig$: Observable<number> = this.pageGroups$.pipe(
        filter((pageGroups) => !!pageGroups),
        map((groups: PageGroup[]) => {
            const moduleGroup = groups.filter((g) => g.id === 'modules');
            const optimizerPage = moduleGroup ? moduleGroup[0].children.filter((c) => c.id === 'optimizer') : null;
            return optimizerPage ? optimizerPage[0].popoutPanelConfiguration : -1;
        }),
    );

    public checkIfFeatureEnabled(groupId: string, featureId: string) {
        return this.pageGroups$.pipe(
            takeUntil(this.unsubscribe$),
            filter((pageGroups) => !!pageGroups),
            map((pageGroups) => {
                let modules = pageGroups.find((group) => group.id === groupId);
                let _feature = modules?.children.find((child) => child.id === featureId);
                return _feature?.enabled;
            }),
        );
    }
}
