import {MeasureType, MetricCategoryTypeCategory, MetricCategoryTypeNumeric, MetricType, ValueType} from './metric';
import {Filter, SimpleUnit} from '@core/interfaces/system/system-common';
import {GeospatialCenterCoordinate} from './viewer-config';
import {VisualizationType} from './visualization';
import {MapLayerDetails} from './layers';

/*
 * Request DTOs
 */
export class GeospatialRequest {
    visualizationType: VisualizationType;
    measure: MeasureType;
    metric: MetricType;
    valueType: ValueType;
    filterList: Filter[];
    studyId?: number;
    valueFilter: MetricCategoryTypeCategory | MetricCategoryTypeNumeric;
    centerConfig: GeospatialCenterCoordinate;
}
export class GeospatialPopupRequest {
    measureType: MeasureType;
    metricType: MetricType;
    valueType: ValueType;
}
export class GeospatialAssetPopupRequest extends GeospatialPopupRequest {
    assetId: string;
    assetClassCode: string;
}
export class GeospatialGroupPopupRequest extends GeospatialPopupRequest {
    groupId: string;
    groupCode: string;
}

/*
 * Response DTOs
 */
export class GeospatialResponse {
    visualizationType: VisualizationType;
    dataDetails: DataDetails;
    data: SpatialEntityData[];
}

export class GeospatialPopupResponse {
    data: string;
    interventionCost: number;
    dataUnit: Unit;
    costUnit: Unit;
    // Passthrough
    measureType: MeasureType;
    metricType: MetricType;
    valueType: ValueType;
}
export class GeospatialAssetPopupResponse extends GeospatialPopupResponse {
    assetId: string;
    assetClassCode: string;
    assetClass: string;
    latitude: string;
    longitude: string;
    bcRatio: number;
}
export class GeospatialGroupPopupResponse extends GeospatialPopupResponse {
    groupId: string;
    groupCode: string;
    groupName: string;
    latitude: string;
    longitude: string;
    count: number;
    bcRatio: number;
}

export class SpatialCustomLayerResponse {
    data: RoadMoratoriumData[] | DistrictData[] | CustomerDemographicData[];
    details: MapLayerDetails[];
}

// Supporting data structures
export class DataDetails {
    valueType: ValueType;
    unit: SimpleUnit;
    // ValueType.CATEGORY
    categoryList?: string[];
    // ValueType.NUMERIC
    min?: number;
    max?: number;
}

export class SpatialEntityData {
    id: string;
    classCode: string;
    count: number;
    data: string;
    displayCategory?: string;
    geoJsonGeometryStr?: string;
    geoJsonGeometry?: GeoJSONGeometry;
}

export class GeoJSONGeometry {
    type: GeoJSONGeometryType;
    coordinates: any[];

    constructor(type: GeoJSONGeometryType, coordinates: any[]) {
        this.type = type;
        this.coordinates = coordinates;
    }
}
export enum GeoJSONGeometryType {
    Point = 'Point',
    LineString = 'LineString',
    Polygon = 'Polygon',
    MultiLineString = 'MultiLineString',
}

export enum CoordinateSystem {
    LAT_LONG = 'LAT_LONG',
    UTM = 'UTM',
}

export interface Unit {
    prefix: string;
    suffix: string;
    symbol: string;
}

// Custom map layer types
export class RoadMoratoriumData {
    id: number;
    closureType: string;
    closureName: string;
    closureDescription: string;
    reason: string;
    status: string;
    streetName: string;
    streetFrom: string;
    streetTo: string;
    dateFrom: string;
    dateTo: string;
    geoJsonGeometryStr?: string;
    geoJsonGeometry?: GeoJSONGeometry;
}

export class DistrictData {
    id: number;
    districtName: string;
    districtDescription: string;
    geoJsonGeometryStr?: string;
    geoJsonGeometry?: GeoJSONGeometry;
}

export class CustomerDemographicData {
    id: number;
    type: string;
    geoJsonGeometryStr?: string;
    geoJsonGeometry?: GeoJSONGeometry;
}

export class GeospatialAreaPmCountRequest {
    filters: Filter[];
    sensitivityId: number;
    workflowItemId: number;
    centerConfig: GeospatialCenterCoordinate;
}

export interface GeospatialAreaPmCountResponse {
    assetCount: number;
    assetClassCount: number;
    assetCost: number;
}

// Alternatives
export class GeospatialRequestAlt {
    workflowItemId: number;
    measure: MeasureType;
    metric: MetricType;
    filters: Filter[];
    centerConfig?: GeospatialCenterCoordinate;
}
