<nb-card *ngIf="!showPlain">
    <nb-card-header>
        <ng-container *ngIf="!additionalBlock || !(additionalBlock.enable | async)">
            <div [ngClass]="!subTitle ? 'singleTitle': ''">
                <div class="primary-header">{{title}}</div>
                <div *ngIf="subTitle" class="sub-header">{{subTitle}}</div>
            </div>
        </ng-container>

        <div *ngIf="additionalBlock && additionalBlock.enable | async" class="d-flex align-items-center">
            <button nbButton class="mr-3" (click)="additionalBlock?.toggleCallback()">
                <nb-icon [icon]="'close-outline'"></nb-icon>
            </button>

            <div *ngIf="additionalBlockTitle | async" class="mr-3 sub-header">{{additionalBlockTitle | async}}</div>

            <div *ngFor="let button of additionalBlockButtons">
                <button
                    [disabled]="!(button.enable | async)"
                    nbButton
                    status="control"
                    class="mr-2 additional-block-btn"
                    (click)="button?.callback()"
                >
                    <nb-icon [icon]="button?.icon" [pack]="button?.pack || 'eva'"></nb-icon>
                    <span *ngIf="button?.label">{{ button?.label }}</span>
                </button>
            </div>
        </div>

        <div class="display-flex">
            <input
                *ngIf="disableGlobalSearch()"
                nbInput
                #search
                type="text"
                placeholder="Search..."
                (blur)="onSearch(search.value)"
            />

            <form class="header-simple-pager" [formGroup]="pagingFormGroup" *ngIf="enablePagePerRecord()">
                <label class="simple-pager-label">Records on page</label>
                <input
                    nbInput
                    formControlName="perPage"
                    type="number"
                    [min]="1"
                    [max]="99"
                    pattern="[0-99]*"
                    placeholder="Rows"
                    class="simple-pager-input"
                />
            </form>

            <div class="vertical-separator" *ngIf="enablePagePerRecord()"></div>
            <div class="header-side-container">
                <div *ngIf="selectColumnBtn?.enable" class="col-1 header-select-columns-button">
                    <ngx-button-multiselect
                        *ngIf="(selectColumnBtn.columns$ |async)?.length"
                        [formControl]="_selectedColumnsControl"
                        [iconDetails]="{icon: 'layout-outline'}"
                        [options$]="selectColumnBtn.columns$"
                        defaultGroupTitle="Columns"
                    ></ngx-button-multiselect>
                </div>

                <div *ngIf="enablePagePerRecord()" class="col-1 header-refresh-button">
                    <button (click)="refresh()" nbButton>
                        <nb-icon [pack]="'eva'" class="icon" icon="refresh"></nb-icon>
                    </button>
                </div>

                <div *ngIf="customButton?.enable">
                    <button nbButton [status]="customButton?.status || 'basic'" (click)="customButton?.callback()">
                        <nb-icon
                            *ngIf="customButton?.icon"
                            [icon]="customButton?.icon"
                            [pack]="customButton?.pack || 'eva'"
                        ></nb-icon>
                        {{customButton.label}}
                    </button>
                </div>

                <div *ngIf="enableTableSetting()" class="col-1 header-setting-button">
                    <ngx-table-settings (downloadFile)="onDownload()"></ngx-table-settings>
                </div>
            </div>
        </div>
    </nb-card-header>

    <nb-card-body>
        <ngx-smart-table
            [settings]="settings"
            [source]="data"
            (custom)="onCustomEventClick($event)"
            (onFilter)="onFilter($event)"
            (rowSelect)="onRowSelect($event)"
            (userRowSelect)="onUserRowSelect($event)"
        ></ngx-smart-table>
    </nb-card-body>
</nb-card>

<ngx-smart-table
    *ngIf="showPlain"
    [settings]="settings"
    [source]="data"
    (custom)="onCustomEventClick($event)"
    (onFilter)="onFilter($event)"
    (rowSelect)="onRowSelect($event)"
    (userRowSelect)="onUserRowSelect($event)"
></ngx-smart-table>
